import React, { Component } from "react";
import {
  Button,
  Card,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Container,
  Alert
} from "reactstrap";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../../actions/authActions";
import { clearErrors } from "../../../actions/errorActions";
import logoTitle from "../../../assets/bautory_logo_&_title_alt.png";

import history from "../../../helper/history";

// TODO: (PENDING) Switch out 'login' logic for 'register' logic
class RegisterPage extends Component {
  state = {
    modal: false,
    name: "",
    email: "",
    password: "",
    msg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { name, email, password } = this.state;

    const user = {
      name,
      email,
      password
    };

    // Attempt to login
    history.push("/");
    this.props.register(user);
  };

  render() {
    return (
      <div className="login">
        <Container className="login-page center">
          <div className="mb-5">
            <img src={logoTitle} alt="Logo" />
          </div>

          <Card className="panel">
            <h3 className="center mt-4">Registrierung bei Bautory</h3>

            <Form className="p-4 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Button
                  className="btn-gradient"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Registrieren
                </Button>
              </FormGroup>
            </Form>
            <a className="signup-link" href="/login">
              <CardFooter>
                <span className="greyed-out">
                  Sie haben schon einen Account?{" "}
                </span>
                <span className="underline">Login</span>
              </CardFooter>
            </a>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(
  mapStateToProps,
  { register, clearErrors }
)(RegisterPage);

//FINAL VERSION:
// import React, { Component } from "react";

// // import logoTitle from "../../assets/bautory_logo_&_title_alt.png";

// // import RoleRegisterStep from "./RoleRegisterStep";
// import TierRegisterStep from "./TierRegisterStep";
// import UserInfoRegisterStep from "./UserInfoRegisterStep";
// import SummaryRegisterStep from "./SummaryRegisterStep";

// import { Container } from "reactstrap";

// class RegisterPage extends Component {
//   state = {
//     step: 1,

//     // STEP 1
//     role: "",

//     // STEP 2
//     tier: "",

//     // STEP 3
//     // User Info
//     name: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     //Company Info
//     companyName: "",
//     companyEmail: "",
//     companyAddress: "",
//     companyTel: "",

//     companyBic: "",
//     companyIban: "",

//     companyInstLocation: "",
//     companyRegistryNum: ""
//   };

//   nextStep = () => {
//     const { step } = this.state;

//     this.setState({
//       step: step + 1
//     });
//   };

//   prevStep = () => {
//     const { step } = this.state;

//     this.setState({
//       step: step - 1
//     });
//   };

//   handleRolePick = role => {
//     this.setState({ role: role });
//     this.nextStep();
//   };

//   handleTierPick = tier => {
//     this.setState({ tier: tier });
//     this.nextStep();
//   };

//   handleChange = input => e => {
//     this.setState({ [input]: e.target.value });
//   };

//   handleSignup = () => {
//     console.log(this.state);
//   };

//   showStep = () => {
//     const {
//       step,
//       role,
//       tier,
//       name,
//       email,
//       password,
//       companyName,
//       companyEmail,
//       companyAddress,
//       companyTel,
//       companyBic,
//       companyIban,
//       companyInstLocation,
//       companyRegistryNum
//     } = this.state;

//     if (step == 1) {
//       //   return <RoleRegisterStep handleRolePick = {this.handleRolePick} handleChange = {this.handleChange} nextStep = {this.nextStep} role = {role}/>
//       // } else if (step == 2){
//       return (
//         <TierRegisterStep
//           handleTierPick={this.handleTierPick}
//           handleChange={this.handleChange}
//           nextStep={this.nextStep}
//           prevStep={this.prevStep}
//           tier={tier}
//         />
//       );
//     } else if (step == 2) {
//       return (
//         <UserInfoRegisterStep
//           handleChange={this.handleChange}
//           nextStep={this.nextStep}
//           prevStep={this.prevStep}
//           name={name}
//           email={email}
//           password={password}
//           companyName={companyName}
//           companyEmail={companyEmail}
//           companyAddress={companyAddress}
//           companyTel={companyTel}
//           companyBic={companyBic}
//           companyIban={companyIban}
//           companyInstLocation={companyInstLocation}
//           companyRegistryNum={companyRegistryNum}
//         />
//       );
//     } else if (step == 3) {
//       return (
//         <SummaryRegisterStep
//           handleSignup={this.handleSignup}
//           prevStep={this.prevStep}
//           role={role}
//           tier={tier}
//           name={name}
//           email={email}
//           password={password}
//           companyName={companyName}
//           companyEmail={companyEmail}
//           companyAddress={companyAddress}
//           companyTel={companyTel}
//           companyBic={companyBic}
//           companyIban={companyIban}
//           companyInstLocation={companyInstLocation}
//           companyRegistryNum={companyRegistryNum}
//         />
//       );
//     }
//   };

//   render() {
//     const { step } = this.state;
//     return (
//       <>
//         <div id="register">
//           <Container className="register-page">
//             <div id="register-process-status">
//               <p>Step {step} of 3</p>
//             </div>
//             <div id="register-step-contents">{this.showStep()}</div>
//           </Container>
//         </div>
//       </>
//     );
//   }
// }

// export default RegisterPage;

//OLD VERSION:
// import React, { Component } from "react";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Input,
//   Container,
//   Alert
// } from "reactstrap";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import { register } from "../../actions/authActions";
// import { clearErrors } from "../../actions/errorActions";
// import logoTitle from "../../assets/bautory_logo_&_title.png";

// class RegisterPage extends Component {
//   state = {
//     modal: false,
//     name: "",
//     email: "",
//     password: "",
//     msg: null
//   };

//   static propTypes = {
//     isAuthenticated: PropTypes.bool,
//     error: PropTypes.object.isRequired,
//     register: PropTypes.func.isRequired,
//     clearErrors: PropTypes.func.isRequired
//   };

//   componentDidUpdate(prevProps) {
//     const { error, isAuthenticated } = this.props;
//     if (error !== prevProps.error) {
//       // Check for register error
//       if (error.id === "REGISTER_FAIL") {
//         this.setState({ msg: error.msg.msg });
//       } else {
//         this.setState({ msg: null });
//       }
//     }

//     // If authenticated close modal
//     if (this.state.modal) {
//       if (isAuthenticated) {
//         this.toggle();
//       }
//     }
//   }

//   toggle = () => {
//     // Clear errors
//     this.props.clearErrors();
//     this.setState({
//       modal: !this.state.modal
//     });
//   };

//   onChange = e => {
//     this.setState({ [e.target.name]: e.target.value });
//   };

//   onSubmit = e => {
//     e.preventDefault();

//     const { name, email, password } = this.state;

//     // Create a user object
//     const newUser = {
//       name,
//       email,
//       password
//     };

//     //Attempt to register
//     this.props.register(newUser);
//   };

//   render() {
//     return (
//       <div>
//         <Container className="login-page">
//           <div className="mb-5">
//             <img src={logoTitle} alt="Logo" />
//           </div>
//           {this.state.msg ? (
//             <Alert color="danger">{this.state.msg}</Alert>
//           ) : null}
//           <Form onSubmit={this.onSubmit}>
//             <FormGroup>
//               <Input
//                 type="text"
//                 name="name"
//                 id="name"
//                 placeholder="Name"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Input
//                 type="email"
//                 name="email"
//                 id="email"
//                 placeholder="Email"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Input
//                 type="password"
//                 name="password"
//                 id="password"
//                 placeholder="Password"
//                 className="mb-3"
//                 onChange={this.onChange}
//               />
//               <Button color="dark" style={{ marginTop: "2rem" }} block>
//                 Register
//               </Button>
//             </FormGroup>
//           </Form>
//         </Container>
//       </div>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
//   error: state.error
// });

// export default connect(
//   mapStateToProps,
//   { register, clearErrors }
// )(RegisterPage);
