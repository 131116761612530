import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyFromUser } from "../../../../../actions/companyActions";

import classnames from "classnames";

import Avatar from "@material-ui/core/Avatar";

import AddCompanyMemberModal from "./accountSettings/tabs/company/AddCompanyMemberModal";
import ProfileTab from "./accountSettings/tabs/ProfileTab";

class CompanyPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  componentDidMount() {
    this.props.getCompanyFromUser();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  membersModuleItem = member => {
    return <div>{/* Company member item */}</div>;
  };

  render() {
    const { user } = this.props.auth;
    const company = this.props.company.company;
    const members = [];
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title">Unternehmen</h4>
          {/* <h4>{company.name}</h4> */}
        </Container>
        <Container>
          <Row>
            <Col sm={3}>
              <Card>
                {user.avatar ? (
                  <Avatar
                    className="float-right"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="account-page-avatar"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
                <div>
                  <p>
                    <span className="greyed-out">Name</span>
                    <p>{company ? `${company.name}` : "none"}</p>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="greyed-out">E-Mail</span>
                    <p>{company ? `${company.email}` : "none"}</p>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="greyed-out">Adresse</span>
                    <p>
                      {company
                        ? `${company.address.street_address +
                            ", " +
                            company.address.zip_code +
                            " " +
                            company.address.city}`
                        : "none"}
                    </p>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="greyed-out">Telefon</span>
                    <p>{company ? `${company.telephone}` : "none"}</p>
                  </p>
                </div>
              </Card>
            </Col>
            <Col sm={9}>
              <Card>
                <Row>
                  <Col>
                    <h5>
                      <b>Rechnungsdaten</b>
                    </h5>
                  </Col>
                  <Col>
                    {/* TODO: Implement editing functionality */}
                    <Button className="float-right">Edit</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <p>
                        <span className="greyed-out">Name</span>
                        <p>{company ? `${company.name}` : "none"}</p>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="greyed-out">E-Mail</span>
                        <p>{company ? `${company.email}` : "none"}</p>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="greyed-out">Adresse</span>
                        <p>
                          {company
                            ? `${company.address.street_address +
                                ", " +
                                company.address.zip_code +
                                " " +
                                company.address.city}`
                            : "none"}
                        </p>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="greyed-out">Telefon</span>
                        <p>{company ? `${company.telephone}` : "none"}</p>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        <h5 className="greyed-out">Bankverbindung</h5>
                        <p>
                          <span className="greyed-out">IBAN </span>
                          <p>
                            {company ? `${company.bank_details.iban}` : "none"}
                          </p>
                        </p>
                        <p>
                          <span className="greyed-out">BIC </span>
                          <p>
                            {company ? `${company.bank_details.bic}` : "none"}
                          </p>
                        </p>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="greyed-out">Steuernummer</span>
                        <p>{company ? `${company.tax_id}` : "none"}</p>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        <h5 className="greyed-out">Unternehmensdaten</h5>
                        <p>
                          <span className="greyed-out">Geschäftsadresse </span>
                          <p>
                            {company
                              ? `${company.commercial_register.inst_location}`
                              : "none"}
                          </p>
                        </p>
                        <p>
                          <span className="greyed-out">
                            Handelsregisternummer
                          </span>
                          <p>
                            {company
                              ? `${company.commercial_register.registry_num}`
                              : "none"}
                          </p>
                        </p>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <h5>
                  <b>Edit Company Info</b>
                </h5>
                <Nav tabs className="project-nav">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Company
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Invoice Info
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Notifications
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Unternehmen
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Rechnungsinformation
              </NavLink>
            </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {/* eslint-disable-next-line */}
                    {this.state.activeTab == 1 ? <ProfileTab /> : null}
                  </TabPane>
                  <TabPane tabId="2">
                    {/* eslint-disable-next-line */}
                    {this.state.activeTab == 2 ? <PasswordTab /> : null}
                  </TabPane>
                  {/* <TabPane tabId="3"> */}
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 3 ? <NotificationsTab /> : null}
            </TabPane>
            <TabPane tabId="4"> */}
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 4 ? <CompanyTab /> : null}
            </TabPane>
            <TabPane tabId="5"> */}
                  {/* eslint-disable-next-line */}
                  {/* {this.state.activeTab == 5 ? <InvoicesTab /> : null}
            </TabPane> */}
                </TabContent>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5>
                      <b>Members</b>
                    </h5>
                  </Col>
                  <Col>
                    <AddCompanyMemberModal />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {members?.length ? (
                      <Table>
                        <thead>
                          <tr className="greyed-out">
                            <th>Status</th>
                            <th>Name</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {members?.map(member => {
                            return this.membersModuleItem(member);
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <ListGroup className="pt-2">
                        <ListGroupItem className="center greyed-out">
                          There are no members yet...
                        </ListGroupItem>
                      </ListGroup>
                    )}
                  </Col>
                  {/* TODO: Test with proper User objects in 'Members' Object */}
                  {/* TODO: Add Status to Members/ Users */}
                  {/* TODO: Make '...' button for Company Member list item */}

                  {/* <ListGroup className="mt-3">
                    {company.members.map(member => {
                      return (
                        <ListGroupItem className="justify-content-between invoice-list mb-2">
                        {member.name}
                        <span className="projectlist-float-right">
                          <strong className="mr-3">{member.role}</strong>
                          <div className="more-info-button">
                            <ProjectCraftsmanModal
                              project={project}
                              craftsman={craftsman}
                            />
                          </div>
                        </span>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup> */}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CompanyPage.propTypes = {
  getCompanyFromUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company
});
export default connect(
  mapStateToProps,
  { getCompanyFromUser }
)(CompanyPage);
