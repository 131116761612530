import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { addInvoice } from "../../../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../assets/NeueBaustelle.svg";

class AddInvoiceModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newInvoice = {
      invoice_name: this.state.invoice_name,
      biller: this.state.biller,
      issue_date: this.state.issue_date,
      deadline_date: this.state.deadline_date,
      price: this.state.price
    };
    // Add project via addProject action
    this.props.addInvoice(this.props.projectId, newInvoice);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Rechnung erstellt",
      text: "Die Rechnung wurde erstellt und dem Projekt zugewiesen.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right ml-2"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Neue Rechnung anlegen
          </ModalHeader>
          <ModalBody className="project-modal">
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Rechnungs name</Label>
                      <Input
                        type="text"
                        name="invoice_name"
                        id="invoice"
                        placeholder="Rechnungs name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Rechnungssteller</Label>
                      <Input
                        type="text"
                        name="biller"
                        id="invoice"
                        placeholder="Handwerker eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Erstellungsdatum</Label>
                      <Input
                        type="date"
                        name="issue_date"
                        id="invoice"
                        placeholder="dd/mm/yy"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Rechnungsziel</Label>
                      <Input
                        type="date"
                        name="deadline_date"
                        id="invoice"
                        placeholder="dd/mm/yy"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Preis</Label>
                      <Input
                        type="text"
                        name="price"
                        id="invoice"
                        placeholder="Preis"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Rechnung hochladen</Label>
                      <Input
                        className="input-file"
                        type="file"
                        name="invoice_file"
                        id="invoice"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Rechnung anlegen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  invoice: state.invoice
});

export default connect(
  mapStateToProps,
  { addInvoice }
)(AddInvoiceModal);
