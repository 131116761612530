import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { addCraftsman } from "../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../assets/NeueBaustelle.svg";

class AddCostgroupModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    this.props.addCraftsman(
      this.props.projectId,
      this.state.name,
      this.state.email
    );
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right ml-2"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          Kostengruppe hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Neue Kostengruppe anlegen
          </ModalHeader>
          <ModalBody className="project-modal">
            {/* Determine fields to fill */}
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">E-Mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="craftsman"
                        placeholder="E-Mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="craftsman"
                        placeholder="Name eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Immobilie anlegen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman }
)(AddCostgroupModal);
