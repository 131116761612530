import React, { Component } from "react";
import {
  Button,
  Card,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Container,
  Alert
} from "reactstrap";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import logoTitle from "../../assets/bautory_logo_&_title_alt.png";

import history from "../../helper/history";

class LoginPage extends Component {
  state = {
    modal: false,
    email: "",
    password: "",
    msg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else {
        this.setState({ msg: null });
      }
    }

    // If authenticated close modal
    if (this.state.modal) {
      if (isAuthenticated) {
        this.toggle();
      }
    }
  }

  toggle = () => {
    // Clear errors
    this.props.clearErrors();
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const { email, password } = this.state;

    const user = {
      email,
      password
    };

    // Attempt to login
    history.push("/");
    this.props.login(user);
  };

  render() {
    return (
      <div className="login">
        <Container className="login-page center">
          <div className="mb-5">
            <img src={logoTitle} alt="Logo" />
          </div>

          <Card className="panel">
            <h3 className="center mt-4">Login bei Bautory</h3>

            <Form className="p-4 custom-form" onSubmit={this.onSubmit}>
              {this.state.msg ? (
                <Alert color="danger">{this.state.msg}</Alert>
              ) : null}
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  className="mb-3"
                  onChange={this.onChange}
                />
                <Button
                  className="btn-gradient"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Login
                </Button>
              </FormGroup>
            </Form>
            <a className="signup-link" href="/register">
              <CardFooter>
                <span className="greyed-out">Sie haben noch kein Konto? </span>
                <span className="underline">Registrieren</span>
              </CardFooter>
            </a>
          </Card>
          <a className="forgot-pw-link" href="/forgot_password">
            Passwort vergessen?
          </a>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(
  mapStateToProps,
  { login, clearErrors }
)(LoginPage);
