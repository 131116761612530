import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  Nav,
  NavItem,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logout from "../../../authentication/Logout";

import Avatar from "@material-ui/core/Avatar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
class AccountModal extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  returnUserRole = roleNum => {
    let role;
    if (roleNum == 0) {
      role = "Admin";
    }
    return role;
  };

  render() {
    const { user } = this.props.auth;
    return (
      <div>
        <NavLink onClick={this.toggle} href="#">
          <strong>{user ? `${user.name.replace(/ .*/, "")}` : ""}</strong>{" "}
          {user.avatar ? (
            <Avatar
              className="float-right"
              src="../../assets/DefaultProfilePic.svg"
            />
          ) : (
            <Avatar
              className="float-right navbar-avatar"
              src="../../assets/DefaultProfilePic.svg"
            />
          )}
        </NavLink>
        <Modal
          className="account-modal list-modal"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>
            <Row>
              <Col sm={3}>
                {user.avatar ? (
                  <Avatar
                    className="float-right"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="avatar"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={9}>
                <p>{user ? user.name : "Username"}</p>
                <p className="role greyed-out">
                  {user ? this.returnUserRole(user.role) : "Role"}
                </p>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Nav className="primary-nav" vertical>
              {/* <NavItem onClick={() => this.toggle()}>
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/user"
                  activeClassName="active"
                >
                  <p>Profil</p>
                </NavLink>
              </NavItem>
              <NavItem onClick={() => this.toggle()}>
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/company"
                  activeClassName="active"
                >
                  <p>Unternehmen</p>
                </NavLink>
              </NavItem> */}
              <NavItem onClick={() => this.toggle()}>
                <NavLink
                  tag={RRNavLink}
                  exact
                  to="/settings"
                  activeClassName="active"
                >
                  <Row>
                    <Col sm={1}>
                      <FontAwesomeIcon icon={faCog} />
                    </Col>
                    <Col>
                      <p>Einstellungen</p>
                    </Col>
                  </Row>
                </NavLink>
              </NavItem>
              <NavItem onClick={() => this.toggle()}>
                <Logout />
              </NavItem>
            </Nav>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(AccountModal);
