import { GET_COMPANY_FROM_USER } from "../actions/types.js"

const initialState = {
	company: null,
	loading: false
}

export default function(state = initialState, action) {
	switch(action.type){
		case GET_COMPANY_FROM_USER:
			return{
				...state,
				company: action.payload,
				loading: false
			};
			default: return state
		}
}