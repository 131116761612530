import client from '../helper/apolloClient';
import gql from 'graphql-tag';
import {USER_LOADED, USER_LOADING} from './types';
import {UPDATE_USER} from '../graphql/mutation/user/updateUser'
export const updateUser = ({name, email, password}) => dispatch => {
    console.log(email, name, password)
    dispatch(setUserLoading());
    client.mutate({
     variables: {
         user: {
            name,
            email, 
            password
        }
     },
      mutation: UPDATE_USER
    })
      .then(res => 
        dispatch({ type: USER_LOADED, payload: res.data.updateUser })
        )
      .catch(err => {
        console.log(err)
        //dispatch(returnErrors(err.response.data, err.response.status));
      });
  };


export const setUserLoading = () => {
    return {
      type: USER_LOADING
    };
  };