import React, { Component } from "react";
import ProjectsList from "../../projects/ProjectsList";
import { Container } from "reactstrap";

class UploadInvoicePage extends Component {
  render() {
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title">Wem möchten Sie eine Rechnung stellen?</h4>
        </Container>
        <Container>
          <ProjectsList page={"UploadInvoicePage"} isArchived={false} />
        </Container>
      </div>
    );
  }
}

export default UploadInvoicePage;
