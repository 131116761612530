import React, { Component } from "react";
import { Container, Badge, Table, Row, Card, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ProjectSingleAuftragTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  // Get the right fields in (backend & frontend)
  auftragListItem = auftrag => {
    return (
      <tr>
        <td>{auftrag.status}</td>
        <td>{auftrag.designation}</td>
        <td>{auftrag.budget_netto}</td>
      </tr>
    );
  };

  render() {
    // const project = this.props.project;
    return (
      <Container className="vergabeeinheit p-0">
        <Row className="mb-2 mt-3">
          <Col>
            <Badge className={"status-col mr-3 float-left"}>Beauftragt</Badge>
            <h5>
              <b>Freimachen & Herrichten</b>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Überblick</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <tbody>
                    <tr>
                      <td>Vergabeeinheit</td>
                      <td>VE 2 - Erdarbeiten</td>
                    </tr>
                    <tr>
                      <td>Auftragnehmer</td>
                      <td>Bauunternehmen Mirkwald</td>
                    </tr>
                    <tr>
                      <td>Auftraggeber</td>
                      <td>Stadt Berlin</td>
                    </tr>
                    <tr>
                      <td>Kostenstelle</td>
                      <td>38800</td>
                    </tr>
                    <tr>
                      <td>Auftragsnummer</td>
                      <td>01_190</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>01_190</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Auftragsbestandteile</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Betrag (netto)</th>
                      <th>Betrag (USt.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Hauptauftrag</td>
                      <td>Freimachen & Herrichten</td>
                      <td>70.300,00 €</td>
                      <td>12.000,00 €</td>
                    </tr>
                    <tr>
                      <td>Nachträge</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <Badge className={"status-col mr-3 float-left"}>
                          Anerkannt
                        </Badge>
                      </td>
                      <td>Konzeptionalisi</td>
                      <td>6.900,00 €</td>
                      <td>1.400,00 €</td>
                    </tr>
                    <tr>
                      <td>
                        <Badge className={"status-col mr-3 float-left"}>
                          Anerkannt
                        </Badge>
                      </td>
                      <td>Nacharbeiten der oberen Schicht</td>
                      <td>20.780,00 €</td>
                      <td>3.980,00 €</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>97.980,00 €</td>
                      <td>17.380,00 €</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProjectSingleAuftragTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectSingleAuftragTab));
