import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { createAllocationUnit } from "../../../../../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../../../assets/NeueBaustelle.svg";

class NewVergabeeinheitModal extends Component {
  state = {
    modal: false,
    costgroup: null,
    name: "",
    bezeichnung: "",
    beschreibung: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeSelect = e => {
    this.setState({ costgroup: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    const allocationunit = {
      name: this.state.bezeichnung,
      description: this.state.beschreibung,
      costgroups: [this.state.costgroup]
    };
    this.props.createAllocationUnit(this.props.project.id, allocationunit);
    // Close modal
    this.toggle();
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Bezeichnung*</Label>
                      <Input
                        type="text"
                        name="bezeichnung"
                        id="vergabeeinheit"
                        placeholder="Bezeichnung eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Beschreibung</Label>
                      <Input
                        type="textarea"
                        name="beschreibung"
                        id="vergabeeinheit"
                        placeholder="Beschreibung eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                {/* TODO: Set labels left of input field & determine placeholders/values */}
                <Row className="mb-3" form>
                  <Col>
                    <h5>Kostengruppe zuweisen</h5>
                    <Row>
                      <Col>
                        <Label>Kostengruppe</Label>
                        <Input
                          type="select"
                          name="kostengruppe_name"
                          id="nachtrag"
                          onChange={this.changeSelect}
                          value={this.state.costgroup}
                        >
                          <option value="" disabled selected></option>
                          {this.props.project.budget.map(costgroup => {
                            return (
                              <option value={costgroup.id}>
                                {costgroup.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col>
                        <Label>Prozent</Label>
                        <Input
                          type="text"
                          name="kostengruppe_percent"
                          id="vergabeeinheit"
                          placeholder=""
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col>
                        <Label>Verteiltes Budget</Label>
                        <Input
                          type="text"
                          name="kostengruppe_budget"
                          id="vergabeeinheit"
                          placeholder=""
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Button className="float-left btn-transparent mt-2">
                      <div className="button-icon">
                        <NeueBaustelleSvg />
                      </div>
                      Hinzufügen
                    </Button>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman,
  project: state.project
});

export default connect(
  mapStateToProps,
  { createAllocationUnit }
)(NewVergabeeinheitModal);
