import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { addCraftsman } from "../../../../../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../../../assets/NeueBaustelle.svg";

class AddCompanyMemberModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    // this.props.addCraftsman(this.props.projectId, this.state.name, this.state.email);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="add-company-member-modal">
        <Button
          color="dark"
          className="tab-button float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          Add Member
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Add New Member
          </ModalHeader>
          <ModalBody className="project-modal">
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">E-Mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="craftsman"
                        placeholder="E-Mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="role">Role</Label>
                      <Input type="select" name="role" id="role">
                        <option>Geschäftsführer</option>
                        <option>Projektsteuerer</option>
                        <option>Bauleiter</option>
                        <option>Planer</option>
                        <option>Assistenz</option>
                        <option>Beobachter</option>
                        <option>Externer Rechnungsteller</option>
                        <option>Externer Rechnunsprüfer</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Add Member
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman }
)(AddCompanyMemberModal);
