import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { addCraftsman } from "../../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";

class NewTaskModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    // this.props.addCraftsman(this.props.projectId, this.state.name, this.state.email);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Name*</Label>
                      <Input
                        type="text"
                        name="name"
                        id="task"
                        placeholder="Name eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Beschreibung</Label>
                      <Input
                        type="textarea"
                        name="beschreibung"
                        id="task"
                        placeholder="Beschreibung eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="deadline">Deadline</Label>
                      <Input
                        type="date"
                        name="deadline"
                        id="task"
                        placeholder="dd/mm/yy"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  className="float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Anlegen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman }
)(NewTaskModal);
