import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent
} from "reactstrap";

import StammdatenCraftsmenTab from "./tabs/StammdatenCraftsmenTab";
import StammdatenProjekteTab from "./tabs/StammdatenProjekteTab";
import StammdatenInvoiceFlowTab from "./tabs/StammdatenInvoiceFlowTab";
import StammdatenUsersTab from "./tabs/StammdatenUsersTab";
import StammdatenCostGroupsTab from "./tabs/StammdatenCostGroupsTab";
import StammdatenClientsTab from "./tabs/StammdatenClientsTab";

import { getProjects } from "../../../../../actions/projectActions";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class StammdatenPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  componentDidMount() {
    this.props.getProjects();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  // TODO: Add "Hinzufügen" button to every tab
  render() {
    const projects = this.props.project.projects;
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title mb-5">Stammdaten</h4>
        </Container>
        <Container>
          <Nav tabs className="project-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Auftragnehmer
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Auftraggeber
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Immobilien
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Kostengruppen
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Benutzer
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                Rechnungslauf
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 1 ? (
                <StammdatenCraftsmenTab projects={projects} />
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 2 ? (
                <StammdatenClientsTab projects={projects} />
              ) : null}
            </TabPane>
            <TabPane tabId="3">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 3 ? (
                <StammdatenProjekteTab projects={projects} />
              ) : null}
            </TabPane>
            <TabPane tabId="4">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 4 ? (
                <StammdatenCostGroupsTab projects={projects} />
              ) : null}
            </TabPane>
            <TabPane tabId="5">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 5 ? <StammdatenUsersTab /> : null}
            </TabPane>
            <TabPane tabId="6">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 6 ? <StammdatenInvoiceFlowTab /> : null}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

StammdatenPage.propTypes = {
  getProjects: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProjects }
)(StammdatenPage);
