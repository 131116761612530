import React, { Component } from "react";

import AppNavbar from "../navigation/navbar/AppNavbar";
import DashboardPage from "./main_pages/dashboard/DashboardPage";
import YourProjectsPage from "./main_pages/projects/YourProjectsPage";
import PostfachPage from "./main_pages/PostfachPage";
import ProjectPage from "./main_pages/projects/single_project/ProjectPage";
import SettingsPage from "./main_pages/user/accountSettings/SettingsPage";
import SupportPage from "./main_pages/SupportPage";
import UserPage from "./main_pages/user/UserPage";
import CompanyPage from "./main_pages/user/CompanyPage";
import UploadInvoicePage from "./main_pages/dashboard/create_button/UploadInvoicePage";
import UploadInvoiceMethodPage from "./main_pages/dashboard/create_button/UploadInvoiceMethodPage";
// import history from "../../helper/history";

/* eslint-disable-next-line */
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AufgabenPage from "./main_pages/tasks/AufgabenPage";
import StammdatenPage from "./main_pages/stammdaten/StammdatenPage";
import NotFoundPage from "./main_pages/not_found_page";

class MainContent extends Component {
  render() {
    return (
      <div
        className={`main-content ${
          this.props.sidebarOpen ? "sidebarOpen" : "sidebarClosed"
        }`}
      >
        <AppNavbar toggleSidebar={this.props.toggleSidebar} />
        <Switch>
          <Route
            exact
            path="/"
            render={props => <DashboardPage {...props} testProp={"TestProp"} />}
          />
          <Route path="/myProject/:id" component={ProjectPage} />
          <Route path="/projects" component={YourProjectsPage} />
          <Route path="/aufgaben" component={AufgabenPage} />
          <Route path="/stammdaten" component={StammdatenPage} />
          <Route path="/postfach" component={PostfachPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/support" component={SupportPage} />
          <Route path="/user" component={UserPage} />
          <Route path="/company" component={CompanyPage} />
          <Route exact path="/uploadInvoice" component={UploadInvoicePage} />
          <Route
            path="/uploadInvoice/:id"
            component={UploadInvoiceMethodPage}
          />
          <Route path="/*" component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

export default MainContent;
