import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  FormText
} from "reactstrap";
import { connect } from "react-redux";
import {
  addCraftsman,
  uploadInvoice
} from "../../../../../../actions/projectActions";
import classnames from "classnames";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../assets/NeueBaustelle.svg";
import { ReactComponent as RechnungSvg } from "../../../../../../assets/Rechnung.svg";
import { ReactComponent as AuftragSvg } from "../../../../../../assets/Auftrag.svg";
import { ReactComponent as NachtragSvg } from "../../../../../../assets/Nachtrag.svg";

class HinzufügenModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      // uploadType: null,
      // selectedUploadType: "0",
      // project: null,
      modal: false,
      name: ""
    };
  }

  // state = {
  //   modal: false,
  //   name: ""
  // };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onFileChangeHandler = e => {
    // console.log(e.target.files[0]);
    this.setState({
      selectedFile: e.target.files[0],
      loaded: 0
    });
  };

  selectFileType = type => {
    console.log(type);
    this.setState({ selectedUploadType: type, uploadType: type });
  };

  onProjectChangeHandler = e => {
    console.log(e.target.value);
    if (e.target.value != this.state.project) {
      this.setState({
        project: e.target.value
      });
    }
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    // TODO: SEND PROJECT AND 'INVOICE' TYPE
    this.props.uploadInvoice(data);
    // Close modal

    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  projectSelectItem = project => {
    return <option value={project.id}>{project.name}</option>;
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="tab-button float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row className="mb-4">
                  <h5>Projekt wählen:</h5>
                  <Input
                    type="select"
                    name="project"
                    id="project"
                    onChange={this.onProjectChangeHandler}
                  >
                    <option value="" disabled selected>
                      Projekt auswählen...
                    </option>
                    {this.props.projects?.map(project => {
                      return this.projectSelectItem(project);
                    })}
                  </Input>
                </Row>
                <Row>
                  <h5>Dem Projekt hinzufügen:</h5>
                </Row>
                <Row>
                  <Col
                    className={classnames("hinzufügen-option", {
                      active: this.state.selectedUploadType === "rechnung"
                    })}
                  >
                    <div
                      className="option-card"
                      onClick={() => this.selectFileType("rechnung")}
                    >
                      <div className="button-icon mt-2 mb-3">
                        <RechnungSvg />
                      </div>
                      <p className="center">Rechnung</p>
                    </div>
                  </Col>
                  <Col
                    className={classnames("hinzufügen-option", {
                      active: this.state.selectedUploadType === "auftrag"
                    })}
                  >
                    <div
                      className="option-card"
                      onClick={() => this.selectFileType("auftrag")}
                    >
                      <div className="button-icon mb-1">
                        <AuftragSvg />
                      </div>
                      <p className="center">Auftrag</p>
                    </div>
                  </Col>
                  <Col
                    className={classnames("hinzufügen-option", {
                      active: this.state.selectedUploadType === "nachtrag"
                    })}
                  >
                    <div
                      className="option-card"
                      onClick={() => this.selectFileType("nachtrag")}
                    >
                      <div className="button-icon mt-2 mb-3">
                        <NachtragSvg />
                      </div>
                      <p className="center">Nachtrag</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <h5 className="mt-4">Als PDF hochladen:</h5>
                  {/* <h5 className="mt-4">{this.state.selectedFile?.type}</h5> */}
                </Row>
                <Row>
                  <Input
                    type="file"
                    name="file"
                    onChange={this.onFileChangeHandler}
                    id="exampleFile"
                    className="input-file"
                  />
                  <FormText color="muted">
                    Disclaimer for uploading files into our system; storing, and
                    going through our AI for use throughout.
                  </FormText>
                </Row>
                {/* <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">E-Mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="craftsman"
                        placeholder="E-Mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                  <div className="linediv">
                      <Label for="role">Role</Label>
                      <Input type="select" name="role" id="role">
         			 					<option>Geschäftsführer</option>
          							<option>Projektsteuerer</option>
          							<option>Bauleiter</option>
          							<option>Planer</option>
          							<option>Assistenz</option>
												<option>Beobachter</option>
												<option>Externer Rechnungsteller</option>
												<option>Externer Rechnunsprüfer</option>
        							</Input>
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Add Member
                </Button> */}
                <Row>
                  <Button className="btn-primary mt-4" color="dark" block>
                    Upload
                  </Button>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman, uploadInvoice }
)(HinzufügenModal);
