import gql from 'graphql-tag';
 // Login user
export const REMOVE_INVOICE_MUTATION = gql`
    mutation removeInvoice($projectId: String!, $invoiceId: String!, $companyId: String!) {
        removeInvoice(projectId: $projectId, invoiceId: $invoiceId, companyId: $companyId) {
            id
            name
            client
            companyId
            address {
              street_address
              zip_code
              city
            }
            craftsmen {
              ... on Craftsman {
                name,
                address {
                  street_address
                  zip_code
                  city
                }
                status
              }
            }
            invoices {
              ... on Invoice {
                price
                biller
                deadline_date
                status
                issue_date
                invoice_name
              }
            }
            inbox {
              id
              creater
              type
              sourceId
            }
            start_date
            end_date
            project_id
            budget
            is_complete
            is_archived
        }
  }
`;