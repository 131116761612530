import React, { Component } from "react";
import { Container, ListGroup, ListGroupItem, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Avatar from "@material-ui/core/Avatar";

class ProjectActivityTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  // inboxItem = (creater, type) =>{
  //   switch (type) {
  //     case "NEW_INVOICE":
  //       return (<ListGroupItem className="justify-content-between invoice-list mb-2">{creater} uploaded an Invoice</ListGroupItem>)
  //     default:
  //       break;
  //   }
  // }
  inboxItem = (user, type) => {
    switch (type) {
      case 0:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.names} created ALLOCATIONUNIT </b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 1:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} created ASSIGNMENT </b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 2:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} created COSTGROUP </b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 3:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} created SUBCOSTGROUP </b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      default:
        break;
    }
  };

  // TODO: SWITCH OUT DEPRECATED PROJECT.INBOX FOR PROJECT.HISTORY
  render() {
    const project = this.props.project;
    return (
      <Container className="p-0">
        <ListGroup className="mt-3">
          {project?.history?.length ? (
            project.history.map(item => {
              return this.inboxItem(item.userId, item.type);
            })
          ) : (
            <p className="greyed-out pt-3 px-3">
              Keine neuigkeiten in dieser Baustelle.
            </p>
          )}
        </ListGroup>
      </Container>
    );
  }
}

ProjectActivityTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectActivityTab));
