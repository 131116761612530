import React, { Component } from "react";
import {
  Container,
  Table,
  ListGroupItem,
  Button,
  Card,
  Row,
  Col,
  Progress,
  ListGroup
} from "reactstrap";

// import { ReactComponent as DefaultProfilePicSvg } from "../../assets/DefaultProfilePic.svg"
import Avatar from "@material-ui/core/Avatar";

import { withRouter } from "react-router-dom";
import {
  getProject,
  toggleIsArchived,
  toggleIsComplete,
  deleteProject
} from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
import Toggle from "react-toggle";
import "react-toggle/style.css";

class ProjectDashboardTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  permDeleteProject = id => {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Das Löschen der Baustelle kann nicht rückgängig gemacht werden.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#ff635f",
      cancelButtonColor: "#979797",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.deleteProject(id);
        Swal.fire({
          title: "Baustelle gelöscht",
          text:
            "Die Baustelle wurde gelöscht und befindet sich nicht mehr unter Ihren Baustellen.",
          icon: "success",
          confirmButtonColor: "#4a8950",
          confirmButtonText: "Ok"
        });
      }
    });
  };

  isArchivedToggle = id => {
    Swal.fire({
      title: "Baustelle archivieren?",
      text:
        "Mit dieser Aktion archivieren Sie die Baustelle " +
        this.props.project.name +
        ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#4a8950",
      cancelButtonColor: "#ff635f",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.toggleIsArchived(id);
      }
    });
  };

  isCompleteToggle = id => {
    Swal.fire({
      title: "Baustelle beenden?",
      text:
        "Mit dieser Aktion beenden Sie die Baustelle " +
        this.props.project.name +
        ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#4a8950",
      cancelButtonColor: "#ff635f",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.toggleIsComplete(id);
      }
    });
  };

  calculateExpenses = invoices => {
    var totalCost = 0;
    invoices.forEach(invoice => {
      if (invoice.status == 1) {
        totalCost += invoice.price;
      }
    });
    return totalCost;
  };

  getUsedBudgetPercentage(budget, invoices) {
    var percentage = 0;
    var totalCost = 0;
    invoices.forEach(invoice => {
      if (invoice.status == 1) {
        totalCost += invoice.price;
      }
    });
    percentage = (totalCost / budget) * 100;
    if (percentage > 100) {
      return 100;
    } else if (percentage < 0) {
      return 0;
    } else {
      return percentage;
    }
  }

  countOpenInvoices = invoices => {
    var openInvoicesCount = 0;
    invoices.forEach(invoice => {
      if (invoice.status == 0) {
        openInvoicesCount += 1;
      }
    });
    return openInvoicesCount;
  };

  // TODO: Get user image, notification path, and calculate time appropriately
  inboxItem = (user, type) => {
    switch (type) {
      case 0:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.names} hat eine Vergabeeinheit erstellt</b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 1:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} hat eine Auftrag erstellt</b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 2:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} hat eine Kostengruppe erstellt</b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      case 3:
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {user.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">{user?.name}</p>
                </Row>
                <Row>
                  <p>
                    <b>{user?.name} hat eine Unter-Kostengruppe erstellt</b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      default:
        break;
    }
  };

  render() {
    const project = this.props.project;
    // Calculate days left
    var today_date = new Date().toISOString().substring(0, 10);
    var project_end_date = "";
    if (project) {
      project_end_date = project?.end_date?.substring(0, 10);
    }
    const timeDiff = new Date(project_end_date) - new Date(today_date);
    const project_days_left = timeDiff / (1000 * 60 * 60 * 24);

    // Get latest 3 invoices in project
    // TODO: Reverse Array first to get "latest" invoices
    var invoices_to_render = [];
    for (var i = 0; i < project?.invoices?.length; i++) {
      invoices_to_render.push(project?.invoices[i]);
    }

    return (
      <Container className="project-dashboard px-0 mt-3">
        <Row>
          <Col xs="6">
            {/* TODO: Decide what other metrics should appear here */}
            <Card>
              <h5>
                <b>Übersicht </b>
                <span className="greyed-out">(netto)</span>
              </h5>
              <Row className="progress-bars">
                <Col>
                  <Row>
                    <Col className="bar-label-col" sm={2}>
                      <p className="float-right">Budget</p>
                    </Col>
                    <Col className="bar-col" sm={10}>
                      <Progress
                        value={
                          25
                          // project
                          //   ? this.getUsedBudgetPercentage(
                          //       project.budget,
                          //       project.invoices
                          //     )
                          //   : 0
                        }
                      />
                      {/* <p>{project? this.getUsedBudgetPercentage(project.budget, project.invoices) : 0}</p> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="bar-label-col" sm={2}>
                      <p className="float-right">TBD</p>
                    </Col>
                    <Col className="bar-col" sm={10}>
                      <Progress
                        value={
                          80
                          // project
                          //   ? this.getUsedBudgetPercentage(
                          //       project.budget,
                          //       project.invoices
                          //     )
                          //   : 0
                        }
                      />
                      {/* <p>{project? this.getUsedBudgetPercentage(project.budget, project.invoices) : 0}</p> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="bar-label-col" sm={2}>
                      <p className="float-right">TBD</p>
                    </Col>
                    <Col className="bar-col" sm={10}>
                      <Progress
                        value={
                          50
                          // project
                          //   ? this.getUsedBudgetPercentage(
                          //       project.budget,
                          //       project.invoices
                          //     )
                          //   : 0
                        }
                      />
                      {/* <p>{project? this.getUsedBudgetPercentage(project.budget, project.invoices) : 0}</p> */}
                    </Col>
                  </Row>
                  {/* <Row className="mt-2">
                    <Col className="bar-label-col" sm={2}>
                      <p className="float-right greyed-out">Metric</p>
                    </Col>
                    <Col className="bar-col" sm={10}>
                      <Progress value={0} />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="bar-label-col" sm={2}>
                      <p className="float-right greyed-out">Metric</p>
                    </Col>
                    <Col className="bar-col" sm={10}>
                      <Progress value={0} />
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Rechnungen</b>
                  </h5>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.props.switchTab("6");
                    }}
                    className="float-right btn-white"
                  >
                    Alle ansehen
                  </Button>
                </Col>
              </Row>
              <Row>
                <Table className="invoices-table">
                  {/* TODO: What does the "Projekt" field mean? */}
                  <thead>
                    <tr className="greyed-out">
                      <th>Beschreibung</th>
                      <th>Projekt</th>
                      <th>Frist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices_to_render?.map(invoice => {
                      if (invoice) {
                        return (
                          <tr>
                            <td>
                              <p>Type</p>
                              <p className="greyed-out">{invoice?.biller}</p>
                              <p className="greyed-out">{invoice?.price} €</p>
                            </td>
                            <td>Factory Berlin</td>
                            <td>{invoice?.issue_date}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </Row>
            </Card>
            {/* <p>
              <span className="greyed-out">Anschrift:</span>{" "}
              {project ? project.address.street_address + ", " + project.address.zip_code + " " + project.address.city : "None"}
            </p>
            <p>
              <span className="greyed-out">Start:</span>{" "}
              {project ? `${project.start_date.split("T")[0]}` : "none"}
            </p>
            <p>
              <span className="greyed-out">Geplantes Ende:</span>{" "}
              {project ? `${project.end_date.split("T")[0]}` : "none"}
            </p> */}
          </Col>
          <Col>
            {/* TODO: Reverse list to get only the 'latest' notifications */}
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Neuigkeiten</b>
                  </h5>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.props.switchTab("7");
                    }}
                    className="float-right btn-white"
                  >
                    Alle ansehen
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* TODO: Should this list style replicate the previous notifications icon in the navbar? */}
                  <ListGroup className="project-dashboard-notifications">
                    {project?.history?.length ? (
                      project?.history?.map(item => {
                        return this.inboxItem(item.userId, item.type);
                      })
                    ) : (
                      <p className="greyed-out pt-3 px-3">
                        Keine Aktivität in dieser Baustelle.
                      </p>
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Card>
            {/* <Card>
              <h4 className="module-title">Status</h4>
              <p>
                <span className="greyed-out">Beendet:</span>
                <button
                  className="overview-button"
                  onClick={this.isCompleteToggle.bind(
                    this,
                    project ? project.id : ""
                  )}
                >
                  <Toggle
                    checked={project ? project.is_complete : false}
                    icons={false}
                  />
                </button>
              </p>
              <p>
                <span className="greyed-out">Archiviert:</span>
                <button
                  className="overview-button"
                  onClick={this.isArchivedToggle.bind(
                    this,
                    project ? project.id : ""
                  )}
                >
                  <Toggle
                    checked={project ? project.is_archived : false}
                    icons={false}
                  />
                </button>
              </p>
            </Card> */}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Card>
              <h4 className="module-title">Überblick</h4>
              <Row>
                <Col className="module-figure">
                  <p className="greyed-out">Tage Übrig</p>
                  <h4>{project ? project_days_left : 0}</h4>
                </Col>
                <Col className="module-figure">
                  <p className="greyed-out">Offene Rechnungen</p>
                  <h4>{project ? this.countOpenInvoices(project.invoices) : 0}</h4>
                </Col>
                <Col className="module-figure">
                  <p className="greyed-out">Handwerker</p>
                  <h4>{project ? project.craftsmen.length : 0}</h4>
                </Col>
              </Row>
            </Card>
            <Card>
              <h4 className="module-title">Umsatz & Ausgaben</h4>
              <Row>
                <Col sm="4">
                  <div className="positive-icon">
                    <FontAwesomeIcon icon={faArrowUp} />
                  </div>
                </Col>
                <Col sm="8">
                  <Row className="module-figure">
                    <h4>{project ? `${project.budget}` : 0}€</h4>
                  </Row>
                  <Row>
                    <p className="greyed-out">Umsatz</p>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <div className="negative-icon">
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                </Col>
                <Col sm="8">
                  <Row className="module-figure">
                    <h4>{project ? this.calculateExpenses(project.invoices) : 0}€</h4>
                  </Row>
                  <Row>
                    <p className="greyed-out">Ausgaben</p>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="6">
            <Card>
              <Button className="delete-btn" 
              onClick={() => this.permDeleteProject(project.id)}
              >
                Baustelle löschen
              </Button>
            </Card>
          </Col>
        </Row> */}
      </Container>
    );
  }
}

ProjectDashboardTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  toggleIsArchived: PropTypes.func.isRequired,
  toggleIsComplete: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  {
    getProject,
    toggleIsArchived,
    toggleIsComplete,
    deleteProject
  }
)(withRouter(ProjectDashboardTab));
