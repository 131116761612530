import gql from "graphql-tag";
// Login user
export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
      name
      client
      companyId
      address {
        street_address
        zip_code
        city
      }
      craftsmen {
        ... on Craftsman {
          id
          name
          address {
            street_address
            zip_code
            city
          }
          status
        }
      }
      invoices {
        ... on Invoice {
          id
          price
          biller
          deadline_date
          status
          issue_date
          invoice_name
        }
      }
      inbox {
        id
        creater
        type
        sourceId
      }
      budget {
        id
        id_number
        name
        budget_netto
        budget_brutto
        ust
        subcostgroup {
          id
          name
          budget_netto
          budget_brutto
        }
      }
      assignment {
        id
        status
        name
        contractor {
          name
        }
        allocationunit {
          name
        }
        assignmentnumber
        budget_netto
        budget_brutto
      }
      history {
        type
        userId {
          name
        }
      }
      allocationunit {
        id
        status
        name
        description
        costgroups {
          id
          name
          budget_netto
        }
      }
      start_date
      end_date
      project_id
      is_complete
      is_archived
    }
  }
`;
