import React, { Component } from "react";

/* eslint-disable-next-line */
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";
import { getCompanyFromUser } from "./actions/companyActions";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";
import LoginPage from "./components/authentication/LoginPage";
import RegisterPage from "./components/authentication/register/RegisterPage";

import SideBar from "./components/authenticated/navigation/SideBar";

import { ApolloProvider } from "@apollo/react-hooks";

import client from "./helper/apolloClient";

import Cookies from "js-cookie";
import { AUTH_TOKEN } from "./helper/constants";
import MainContent from "./components/authenticated/content/MainContent";
import ForgotPasswordPage from "./components/authentication/ForgotPasswordPage";

import history from "./helper/history";

require("dotenv").config();

class App extends Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if (performance.navigation.type == 1) {
        console.log("This page is reloaded");
        const isAuthenticated = Cookies.get("token");
        if (isAuthenticated) {
          store.dispatch(getCompanyFromUser());
        }
      } else {
        console.log("This page is not reloaded");
      }
    }
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    sidebar: true,
    name: ""
  };

  toggleSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar
    });
  };

  render() {
    const isAuthenticated = Cookies.get("token");
    console.log(isAuthenticated);

    const userRole = "contractor";

    const authorized = (
      <Router history={history}>
        <Redirect to="/" />
        <SideBar
          isOpen={this.state.sidebar}
          toggleSidebar={this.toggleSidebar}
        />
        <MainContent
          sidebarOpen={this.state.sidebar}
          toggleSidebar={this.toggleSidebar}
          userRole={userRole}
        />
      </Router>
    );

    const unauthorized = (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/login" />;
            }}
          />
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/register">
            <RegisterPage />
          </Route>
          <Route exact path="/forgot_password">
            <ForgotPasswordPage />
          </Route>
        </Switch>
      </Router>
    );

    // const [sidebarIsOpen, setSidebarOpen] = useState(true);
    // const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
    // const unauthorized = <RegisterPage />;

    return (
      // <Provider store={store}>
      <ApolloProvider client={client}>
        <div className="App">
          {/* <Row className="m-0">
              <Col className="no-padding-left no-padding-right" sm={2}> */}
          {/* </Col>
              <Col className="no-padding-left no-padding-right" sm={10}> */}
          {isAuthenticated ? authorized : unauthorized}
          {/* </Col>
            </Row> */}
        </div>
        {/* <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} /> */}
      </ApolloProvider>
      // </Provider>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  null
)(App);
