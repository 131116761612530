import axios from "axios";
import {
  INVOICE_UPLOADING,
  INVOICE_UPLOADED,
  GET_PROJECTS,
  GET_PROJECT,
  ADD_PROJECT,
  TOGGLE_PROJECT_IS_ARCHIVED,
  DELETE_PROJECT,
  TOGGLE_PROJECT_IS_COMPLETE,
  ADD_INVOICE,
  ADD_CRAFTSMAN,
  PROJECTS_LOADING,
  ADD_COSTGROUP,
  ADD_ALLOCATIONUNIT
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";
import client from "../helper/apolloClient";
import gql from "graphql-tag";
// import { UPLOAD_INVOICE_MUTATION } from "../graphql/mutation/project/uploadInvoice";
import { CREATE_PROJECT_MUTATION } from "../graphql/mutation/project/createProject";
import { ADD_INVOICE_MUTATION } from "../graphql/mutation/project/addInvoice";
import { REMOVE_INVOICE_MUTATION } from "../graphql/mutation/project/removeInvoice";
import { TOGGLE_PROJECT_IS_ARCHIVED_MUTATION } from "../graphql/mutation/project/toggleProjectIsArchived";
import { TOGGLE_PROJECT_IS_COMPLETE_MUTATION } from "../graphql/mutation/project/toggleProjectIsComplete";
import { ADD_CRAFTSMAN_MUTATION } from "../graphql/mutation/project/addCraftsman";
import { REMOVE_CRAFTSMAN_MUTATION } from "../graphql/mutation/project/removeCraftsman";
import { DELETE_PROJECT_MUTATION } from "../graphql/mutation/project/deleteProject";
import { CREATE_COSTGROUP_MUTATION } from "../graphql/mutation/project/createCostGroup";
import { CREATE_ALLOCATIONUNIT_MUTATION } from "../graphql/mutation/project/createAllocationunit";
import Swal from "sweetalert2";

export const uploadInvoice = invoice => dispatch => {
  dispatch({ type: INVOICE_UPLOADING });
  axios
    .post(
      "https://invoice.markov-solutions-api.com/predict?api_token=18561922&post_proc=True&save_ocr_images=False",
      invoice
    )
    .then(res => {
      console.log("INVOICE DATA EXTRACTED:");
      // console.log(res);
      // console.log(res.data.link_to_json);
      // dispatch({ type: GET_PROJECT, payload: res.statusText });
      axios
        .get(res.data.link_to_json)
        .then(res => {
          const invoice_json = res.data;
          console.log(invoice_json);
          // dispatch({
          //   type: INVOICE_UPLOADED,
          //   payload: res.data
          // });
          // client
          //   .mutate({
          //     variables: { invoice_json },
          //     mutation: UPLOAD_INVOICE_MUTATION
          //   })
          //   .then(res =>
          //     dispatch({
          //       type: INVOICE_UPLOADED
          //     })
          //   )
          //   .catch(err => {
          //     dispatch(returnErrors(err.response.data, err.response.status));
          //   });
        })
        .catch(err => {
          console.log(err);
        });

      // dispatch({
      //   type: USER_LOADED,
      //   payload: res.data
      // })
    })
    .catch(err => {
      console.log(err);
    });
};

export const getProjects = () => dispatch => {
  dispatch(setProjectsLoading());
  client
    .query({
      query: gql`
        query projects {
          allProjects {
            id
            name
            client
            budget {
              budget_netto
              budget_brutto
            }
            address {
              street_address
              zip_code
              city
            }
            start_date
            end_date
            project_id
            is_complete
            is_archived
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: GET_PROJECTS, payload: res.data.allProjects });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getProject = id => (dispatch, getState) => {
  dispatch(setProjectsLoading());
  client
    .query({
      variables: { id },
      query: gql`
        query getProject($id: String!) {
          getProject(id: $id) {
            id
            name
            client
            companyId
            address {
              street_address
              zip_code
              city
            }
            craftsmen {
              ... on Craftsman {
                id
                name
                address {
                  street_address
                  zip_code
                  city
                }
                status
              }
            }
            invoices {
              ... on Invoice {
                id
                price
                biller
                deadline_date
                status
                issue_date
                invoice_name
              }
            }
            inbox {
              id
              creater
              type
              sourceId
            }
            budget {
              id
              id_number
              name
              budget_netto
              budget_brutto
              ust
              subcostgroup {
                id
                name
                budget_netto
                budget_brutto
              }
            }
            assignment {
              id
              status
              name
              contractor {
                name
              }
              allocationunit {
                name
              }
              assignmentnumber
              budget_netto
              budget_brutto
            }
            history {
              type
              userId {
                name
              }
            }
            allocationunit {
              id
              status
              name
              description
              costgroups {
                id
                name
                budget_netto
              }
            }
            start_date
            end_date
            project_id
            is_complete
            is_archived
          }
        }
      `
    })
    .then(res => {
      console.log(res);
      dispatch({ type: GET_PROJECT, payload: res.data.getProject });
    })
    .catch(err => {
      console.log(err);
      // dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addProject = project => (dispatch, getState) => {
  client
    .mutate({
      variables: { project },
      mutation: CREATE_PROJECT_MUTATION
    })
    .then(res =>
      dispatch({
        type: ADD_PROJECT,
        payload: res.data.createProject
      })
    )
    .catch(err => {
      console.log(err);
    });
};

// export const deleteProject = id => (dispatch, getState) => {
//   axios
//     .delete(`/api/projects/${id}`, tokenConfig(getState))
//     .then(res =>
//       dispatch({
//         type: DELETE_PROJECT,
//         payload: id
//       })
//     )
//     .catch(err => {
//       dispatch(returnErrors(err.response.data, err.response.status));
//     });
// };

export const toggleIsArchived = id => (dispatch, getState) => {
  client
    .mutate({
      variables: { projectId: id },
      mutation: TOGGLE_PROJECT_IS_ARCHIVED_MUTATION
    })
    .then(res =>
      dispatch({
        type: TOGGLE_PROJECT_IS_ARCHIVED,
        payload: res.data.toggleProjectIsArchived
      })
    )
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const toggleIsComplete = id => (dispatch, getState) => {
  client
    .mutate({
      variables: { projectId: id },
      mutation: TOGGLE_PROJECT_IS_COMPLETE_MUTATION
    })
    .then(res =>
      dispatch({
        type: TOGGLE_PROJECT_IS_COMPLETE,
        payload: res.data.toggleProjectIsComplete
      })
    )
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createCostGroup = (id, costgroup) => (dispatch, getState) => {
  client
    .mutate({
      variables: { projectId: id, costgroup },
      mutation: CREATE_COSTGROUP_MUTATION
    })
    .then(res => {
      dispatch({
        type: ADD_COSTGROUP,
        payload: res.data.createCostGroup
      });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addInvoice = (id, invoice) => (dispatch, getState) => {
  console.log(invoice);
  client
    .mutate({
      variables: { projectId: id, invoice: invoice },
      mutation: ADD_INVOICE_MUTATION
    })
    .then(res =>
      dispatch({
        type: ADD_INVOICE,
        payload: res.data.addInvoice
      })
    )
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addCraftsman = (projectId, name, email) => (
  dispatch,
  getState
) => {
  client
    .mutate({
      variables: { projectId, name, email },
      mutation: ADD_CRAFTSMAN_MUTATION
    })
    .then(res =>
      dispatch({
        type: ADD_CRAFTSMAN,
        payload: res.data.addCraftsman
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeCraftsman = (projectId, craftsmanId, companyId) => (
  dispatch,
  getState
) => {
  client
    .mutate({
      variables: { projectId, craftsmanId, companyId },
      mutation: REMOVE_CRAFTSMAN_MUTATION
    })
    .then(res =>
      dispatch({
        type: GET_PROJECT,
        payload: res.data.removeCraftsman
      })
    )
    .catch(err => {
      console.log(err);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeInvoice = (projectId, invoiceId, companyId) => (
  dispatch,
  getState
) => {
  client
    .mutate({
      variables: { projectId, invoiceId, companyId },
      mutation: REMOVE_INVOICE_MUTATION
    })
    .then(res =>
      dispatch({
        type: GET_PROJECT,
        payload: res.data.removeInvoice
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createAllocationUnit = (projectId, allocationunit) => (
  dispatch,
  getState
) => {
  console.log(allocationunit);
  client
    .mutate({
      variables: { projectId, allocationunit },
      mutation: CREATE_ALLOCATIONUNIT_MUTATION
    })
    .then(res => {
      console.log(res);
      dispatch({
        type: ADD_ALLOCATIONUNIT,
        payload: res.data.createAllocationUnit
      });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteProject = projectId => (dispatch, getState) => {
  client
    .mutate({
      variables: { projectId },
      mutation: DELETE_PROJECT_MUTATION
    })
    .then(res =>
      dispatch({
        type: DELETE_PROJECT,
        payload: res.data.deleteProject
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const setProjectsLoading = () => {
  return {
    type: PROJECTS_LOADING
  };
};
