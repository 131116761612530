import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyFromUser } from "../../../../../actions/companyActions";

import Avatar from "@material-ui/core/Avatar";

class UserPage extends Component {
  componentDidMount() {
    this.props.getCompanyFromUser();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props.auth;
    const company = this.props.company.company;
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title">Ihr Profil</h4>
        </Container>
        <Container>
          <Row>
            <Col sm={3}>
              <Card>
                {user.avatar ? (
                  <Avatar
                    className="float-right"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="account-page-avatar"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
                <div>
                  <p>
                    <span className="greyed-out">Name</span>
                    <p>{user ? `${user.name}` : "none"}</p>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="greyed-out">E-Mail</span>
                    <p>{user ? `${user.email}` : "none"}</p>
                  </p>
                </div>
              </Card>
            </Col>
            <Col sm={9}>
              <Card>
                <Row>
                  <h5>
                    <b>Your User</b>
                  </h5>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

UserPage.propTypes = {
  getCompanyFromUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company
});
export default connect(
  mapStateToProps,
  { getCompanyFromUser }
)(UserPage);
