import Cookies from "js-cookie";
import {API_LINK, AUTH_TOKEN} from "./constants";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import {createHttpLink} from 'apollo-link-http';

const authLink = setContext((_, { headers }) => {
    const authToken = Cookies.get(AUTH_TOKEN);
    return {
        headers: {
            ...headers,
            authorization: authToken ? `Bearer ${authToken}` : ''
        }
    }
})

const httpLink = createHttpLink({
    uri: API_LINK,
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
     defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all',
        },
    }
})
export default client