import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent
} from "reactstrap";
import ProjectDashboardTab from "./tabs/dashboard/ProjectDashboardTab";
import ProjectBudgetTab from "./tabs/budget/ProjectBudgetTab";
import ProjectVergabeanheitenTab from "./tabs/vergabeeinheiten/ProjectVergabeanheitenTab";
import ProjectAufträgeTab from "./tabs/aufträge/ProjectAufträgeTab";

import ProjectSingleVergabeeinheitTab from "./tabs/vergabeeinheiten/ProjectSingleVergabeeinheitTab";
import ProjectSingleAuftragTab from "./tabs/aufträge/ProjectSingleAuftragTab";
import ProjectSingleNachtragTab from "./tabs/nachträge/ProjectSingleNachtragTab";
import ProjectSingleRechnungTab from "./tabs/invoices/ProjectSingleRechnungTab";

import ProjectInvoicesTab from "./tabs/invoices/ProjectInvoicesTab";
import ProjectNachträgeTab from "./tabs/nachträge/ProjectNachträgeTab";
import ProjectActivityTab from "./tabs/ProjectActivityTab";
// import ProjectCraftsmenTab from "./ProjectCraftsmenTab";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ProjectPage extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  render() {
    const project = this.props.project;
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title">{project ? project.name : "None"}</h4>
          <h4 className="greyed-out">{project ? project.client : "None"}</h4>
        </Container>
        <Container>
          <Nav tabs className="project-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Budget
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Vergabeeinheiten
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Aufträge
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Nachträge
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                Rechnungen
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "7" })}
                onClick={() => {
                  this.toggle("7");
                }}
              >
                Neuigkeiten
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 1 ? (
                <ProjectDashboardTab switchTab={this.toggle} />
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 2 ? <ProjectBudgetTab /> : null}
            </TabPane>
            <TabPane tabId="3">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 3 ? (
                <ProjectVergabeanheitenTab switchTab={this.toggle} />
              ) : null}
            </TabPane>
            <TabPane tabId="4">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 4 ? (
                <ProjectAufträgeTab switchTab={this.toggle} />
              ) : null}
            </TabPane>
            <TabPane tabId="5">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 5 ? (
                <ProjectNachträgeTab switchTab={this.toggle} />
              ) : null}
            </TabPane>
            <TabPane tabId="6">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 6 ? (
                <ProjectInvoicesTab switchTab={this.toggle} />
              ) : null}
            </TabPane>
            {/* <TabPane tabId="4">
              eslint-disable-next-line
              {this.state.activeTab == 4 ? <ProjectCraftsmenTab /> : null}
            </TabPane> */}
            <TabPane tabId="7">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 7 ? <ProjectActivityTab /> : null}
            </TabPane>
            <TabPane tabId="8">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 8 ? (
                <ProjectSingleVergabeeinheitTab />
              ) : null}
            </TabPane>
            <TabPane tabId="9">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 9 ? <ProjectSingleAuftragTab /> : null}
            </TabPane>
            <TabPane tabId="10">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 10 ? <ProjectSingleNachtragTab /> : null}
            </TabPane>
            <TabPane tabId="11">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 11 ? <ProjectSingleRechnungTab /> : null}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

ProjectInvoicesTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectPage));
