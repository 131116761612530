import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";

class SupportPage extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <p className="greyed-out my-4">*Link to Helpdocs.io*</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SupportPage;
