import gql from 'graphql-tag';
 // Login user
export const CREATE_ALLOCATIONUNIT_MUTATION = gql`
  mutation createAllocationUnit($projectId: String!, $allocationunit: AllocationunitInput!) {
    createAllocationUnit(projectId: $projectId, allocationunit: $allocationunit){
      id
      status
      name
      description
      costgroups {
        name
        budget_netto
      }
    }
  }
`;