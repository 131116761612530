import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { createCostGroup } from "../../../../../../../../../actions/projectActions";

import Swal from "sweetalert2";

import KostengruppenList from "./KostengruppenList";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../../../../assets/NeueBaustelle.svg";

class AddKostengruppeModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectKostengruppe = (id_number, name) => {
    // Add Kostengruppe
    let costgroup = {
      id_number,
      name,
      budget_netto: 0,
      budget_brutto: 0
    };
    this.props.createCostGroup(this.props.project.id, costgroup);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Kostengruppe hinzufügt",
      text: "Der Kostengruppe " + name + " wurde der Budget hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal
          className="list-module add-kostengruppe"
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Hinzufügen
          </ModalHeader>
          <ModalBody>
            {/* <Button onClick={() => this.props.addKostengruppe("Testing TRUE")}>Console Log</Button> */}
            <KostengruppenList addKostengruppe={this.selectKostengruppe} />
            {/* <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup> */}
            {/* <Row className="mb-4">
									<h5>Projekt wählen:</h5>
									<Input type="select" name="project" id="project">
												<option value="" disabled selected>Projekt auswählen...</option>
         			 					<option>Geschäftsführer</option>
          							<option>Projektsteuerer</option>
          							<option>Bauleiter</option>
          							<option>Planer</option>
          							<option>Assistenz</option>
												<option>Beobachter</option>
												<option>Externer Rechnungsteller</option>
												<option>Externer Rechnunsprüfer</option>
        							</Input>
								</Row>
								<Row>
									<h5>Dem Projekt hinzufügen:</h5>
								</Row>
								<Row>
										<Col className="hinzufügen-option">
											<Button>
                        <div className="button-icon mt-2 mb-3">
                          <RechnungSvg />
                        </div>
												<p>Rechnung</p>
											</Button>
										</Col>
										<Col className="hinzufügen-option">
											<Button>
                        <div className="button-icon mb-1">
                          <AuftragSvg />
                        </div>
												<p>Auftrag</p>
											</Button>
										</Col>
										<Col className="hinzufügen-option">
											<Button>
                        <div className="button-icon mt-2 mb-3">
                          <NachtragSvg />
                        </div>
												<p>Nachtrag</p>
											</Button>
										</Col>
									</Row> */}

            {/* <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">E-Mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="craftsman"
                        placeholder="E-Mail eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                  <div className="linediv">
                      <Label for="role">Role</Label>
                      <Input type="select" name="role" id="role">
         			 					<option>Geschäftsführer</option>
          							<option>Projektsteuerer</option>
          							<option>Bauleiter</option>
          							<option>Planer</option>
          							<option>Assistenz</option>
												<option>Beobachter</option>
												<option>Externer Rechnungsteller</option>
												<option>Externer Rechnunsprüfer</option>
        							</Input>
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Add Member
                </Button> */}
            {/* </FormGroup>
            </Form> */}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { createCostGroup }
)(AddKostengruppeModal);
