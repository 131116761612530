import React, { Component } from "react";
import { Container, Badge, Table, Row, Card, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ProjectSingleNachtragTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  // Get the right fields in (backend & frontend)
  auftragListItem = auftrag => {
    return (
      <tr>
        <td>{auftrag.status}</td>
        <td>{auftrag.designation}</td>
        <td>{auftrag.budget_netto}</td>
      </tr>
    );
  };

  render() {
    // const project = this.props.project;
    return (
      <Container className="vergabeeinheit p-0">
        <Row className="mb-2 mt-3">
          <Col>
            <Badge className={"status-col mr-3 float-left"}>Beauftragt</Badge>
            <h5>
              <b>Freimachen & Herrichten</b>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Informationen</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>VE 2 - Erdarbeiten</td>
                    </tr>
                    <tr>
                      <td>Eingangsdatum</td>
                      <td>Bauunternehmen Mirkwald</td>
                    </tr>
                    <tr>
                      <td>Zugehöriger Auftrag</td>
                      <td>Stadt Berlin</td>
                    </tr>
                    <tr>
                      <td>Höhe der Nachtragsforderung</td>
                      <td>38800</td>
                    </tr>
                    <tr>
                      <td>Höhe der Nachtragsforderung (USt.)</td>
                      <td>01_190</td>
                    </tr>
                    <tr>
                      <td>Geprüfte Nachtragsforderung</td>
                      <td>01_190</td>
                    </tr>
                    <tr>
                      <td>Geprüfte Nachtragsforderung (USt.)</td>
                      <td>Bauunternehmen Mirkwald</td>
                    </tr>
                    <tr>
                      <td>Anerkannte Nachtragsforderung</td>
                      <td>Stadt Berlin</td>
                    </tr>
                    <tr>
                      <td>Anerkannte Nachtragsforderung (USt.)</td>
                      <td>38800</td>
                    </tr>
                    <tr>
                      <td>Nachtragsstatus</td>
                      <td>Anerkannt</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProjectSingleNachtragTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectSingleNachtragTab));
