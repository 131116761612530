import React, { Component } from "react";
import ProjectsList from "./ProjectsList";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";

class YourProjectsPage extends Component {
  render() {
    return (
      <div className="projects">
        <Container className="my-4">
          <h4 className="page-title mb-5">Projekte</h4>
          <Row>
            <Col sm={9}>
              <Card>
                <h5 className="mb-3">
                  <b>Projekte:</b>
                </h5>
                <ProjectsList isArchived={false} />
              </Card>
            </Col>
            {/* TODO: Move Filter component to ProjectsList */}
            <Col sm={3}>
              <Card>
                <h5 className="mb-4">
                  <b>Filter:</b>
                </h5>
                <Form>
                  <FormGroup>
                    <Label for="">Bezeichnung</Label>
                    <Input className="mb-3" type="select" name="" id="">
                      <option value="" disabled selected>
                        Auswählen...
                      </option>
                      <option>Geschäftsführer</option>
                    </Input>
                    <Label for="">Auftraggeber</Label>
                    <Input className="mb-3" type="select" name="" id="">
                      <option value="" disabled selected>
                        Auswählen...
                      </option>
                      <option>Geschäftsführer</option>
                    </Input>
                    <Label for="">Status</Label>
                    <Input className="mb-3" type="select" name="" id="">
                      <option value="" disabled selected>
                        Auswählen...
                      </option>
                      <option>Aktiv</option>
                      <option>Fertig</option>
                    </Input>
                    <Button
                      className="btn-primary"
                      style={{ marginTop: "2rem" }}
                      block
                    >
                      Filtern
                    </Button>
                  </FormGroup>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default YourProjectsPage;
