import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav, NavbarBrand, Button } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import ProjectModal from "../content/main_pages/projects/ProjectModal";
import logoTextTitle from "../../../assets/bautory_logo_&_title.png";
import logoTitle from "../../../assets/bautory_logo.png";

import { ReactComponent as DashboardSvg } from "../../../assets/Dashboard.svg";
import { ReactComponent as BaustellenSvg } from "../../../assets/Baustellen.svg";
import { ReactComponent as ArchiveSvg } from "../../../assets/Archiv.svg";
import { ReactComponent as PostfachSvg } from "../../../assets/Postfach.svg";
import { ReactComponent as NewPostfachSvg } from "../../../assets/NewPostfach.svg";
import { ReactComponent as StammdatenSvg } from "../../../assets/Stammdaten.svg";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let sidebarNavItems;
    if (this.props.isOpen) {
      sidebarNavItems = (
        <Nav vertical className="list-unstyled pb-3">
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "1"
              })}
              tag={Link}
              to={"/"}
              onClick={() => {
                this.toggle("1");
              }}
            >
              <div className="svg-container float-left">
                <DashboardSvg />
              </div>
              Dashboard
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "2"
              })}
              tag={Link}
              to={"/projects"}
              onClick={() => {
                this.toggle("2");
              }}
            >
              <div className="svg-container float-left">
                <BaustellenSvg />
              </div>
              Projekte
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "3"
              })}
              tag={Link}
              to={"/aufgaben"}
              onClick={() => {
                this.toggle("3");
              }}
            >
              <div className="svg-container float-left">
                <PostfachSvg />
              </div>
              Aufgaben
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "4"
              })}
              tag={Link}
              to={"/postfach"}
              onClick={() => {
                this.toggle("4");
              }}
            >
              <div className="svg-container float-left">
                <NewPostfachSvg />
              </div>
              Postfach
            </NavLink>
          </NavItem>
          <NavItem>
            <ProjectModal />
          </NavItem>
          <hr />
          <NavItem>
            <NavLink
              className={classnames({
                "side-item": true,
                active: this.state.activeTab === "5"
              })}
              tag={Link}
              to={"/stammdaten"}
              onClick={() => {
                this.toggle("5");
              }}
            >
              <div className="svg-container float-left">
                <StammdatenSvg />
              </div>
              Stammdaten
            </NavLink>
          </NavItem>
        </Nav>
      );
    } else {
      sidebarNavItems = (
        <Nav vertical className="list-unstyled pb-3">
          <NavItem>
            <NavLink
              className=""
              tag={Link}
              to={"/"}
              onClick={() => {
                this.toggle("1");
              }}
            >
              <div className="svg-container float-left">
                <DashboardSvg />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <NavLink
              className=""
              tag={Link}
              to={"/projects"}
              onClick={() => {
                this.toggle("2");
              }}
            >
              <div className="svg-container float-left">
                <BaustellenSvg />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <NavLink
              className=""
              tag={Link}
              to={"/aufgaben"}
              onClick={() => {
                this.toggle("3");
              }}
            >
              <div className="svg-container float-left">
                <PostfachSvg />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <NavLink
              className=""
              tag={Link}
              to={"/postfach"}
              onClick={() => {
                this.toggle("4");
              }}
            >
              <div className="svg-container float-left">
                <NewPostfachSvg />
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <ProjectModal sidebarIsOpen={this.props.isOpen} />
          </NavItem>
          <NavItem className="mt-5">
            <NavLink
              className=""
              tag={Link}
              to={"/stammdaten"}
              onClick={() => {
                this.toggle("6");
              }}
            >
              <div className="svg-container float-left">
                <StammdatenSvg />
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return (
      <div className={`sidebar ${this.props.isOpen ? "open" : "closed"}`}>
        <div className="sidebar-header">
          {/* <span color="info" style={{ color: "fff" }}>
        &times;
      </span> */}
          <NavbarBrand className="ml-3 mt-2" href="/">
            {this.props.isOpen ? (
              <img src={logoTextTitle} alt="Logo" />
            ) : (
              <img src={logoTitle} alt="Logo" />
            )}
          </NavbarBrand>
        </div>
        <div className="side-menu mt-5">{sidebarNavItems}</div>
      </div>
    );
  }
}

export default SideBar;
