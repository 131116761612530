import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";

import { ReactComponent as UploadInvoiceSvg } from "../../../../../../assets/UploadInvoice.svg";
import { ReactComponent as CreateInvoiceSvg } from "../../../../../../assets/CreateInvoice.svg";

class UploadInvoiceMethodPage extends Component {
  // componentDidMount() {
  //   const projectId = this.props.match.params.id;
  // }

  render() {
    return (
      <div id="page-upload-invoice-method">
        <Container className="my-4">
          <h4 className="page-title">Optionen für Ihre Rechnung</h4>
        </Container>
        <Container>
          {/* TODO: Add Invoice type selector (Rechnung or Nachtrag) */}
          <Row>
            <Col>
              {/* TODO: Add File Upload functionality */}
              <a
                style={{ cursor: "pointer" }}
                // onClick={() => handleTierPick('Tester')}
              >
                <Card className="center">
                  <div className="svg-container">
                    <UploadInvoiceSvg />
                  </div>
                  <h4 className="module-title">Rechnung hochladen</h4>
                  <p className="greyed-out">
                    Laden Sie ihre bereits erstellte Rechnung hoch
                  </p>
                </Card>
              </a>
            </Col>
            <Col>
              {/* TODO: Link to invoice Builder */}
              <a
                style={{ cursor: "pointer" }}
                // onClick={() => handleTierPick('Tester')}
              >
                <Card className="center">
                  <div className="svg-container">
                    <CreateInvoiceSvg />
                  </div>
                  <h4 className="module-title">Rechnung erstellen</h4>
                  <p className="greyed-out">
                    Erstellen Sie die Rechnung mit Hilfe des Bautory Templates
                  </p>
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UploadInvoiceMethodPage;
