import React, { Component } from "react";

/* eslint-disable-next-line */
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  InputGroup,
  InputGroupAddon,
  Button,
  Input
} from "reactstrap";
import "../../../../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NotificationsModal from "./NotificationsModal";
import AccountModal from "./AccountModal";
import ProjectModal from "../../content/main_pages/projects/ProjectModal";
import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

class AppNavbar extends Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    const userRole = this.props.userRole;

    let secondaryNavItems;
    if (userRole == "contractor") {
      secondaryNavItems = (
        <Nav className="mx-auto" navbar>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/">
              Dashboard
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/projects">
              Projekte
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/aufgaben">
              Aufgaben
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/stammdaten">
              Stammdaten
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/postfach">
              Postfach
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <ProjectModal />
          </NavItem>
          {/* <NavItem className="ml-4">
        <NavLink id="btn-upload-invoice" className="nav-link" to="/uploadInvoice">Rechnung hochladen</NavLink>
      </NavItem> */}
        </Nav>
      );
    } else if (userRole == "craftsman") {
      secondaryNavItems = (
        <Nav className="mx-auto" navbar>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/">
              Dashboard
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/projects">
              Baustellen
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/archive">
              Archiv
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink className="nav-link" to="/offers">
              Anfragen
            </NavLink>
          </NavItem>
          <NavItem className="ml-4">
            <NavLink
              id="btn-upload-invoice"
              className="nav-link"
              to="/uploadInvoice"
            >
              Rechnung hochladen
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return (
      <div>
        <Navbar expand="sm" className="mt-1 mb-1 navbar-div primary-nav">
          <NavbarBrand onClick={this.props.toggleSidebar}>
            <div className="toggle-sidebar-btn svg-container ml-auto">
              <FontAwesomeIcon icon={faBars} />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <InputGroup className="mr-5 navbar-search">
                <Input placeholder="Suche nach Rechnungen, Projekten und Kunden..." />
                <InputGroupAddon addonType="append">
                  <Button>Suchen</Button>
                </InputGroupAddon>
              </InputGroup>
              <NavItem className="ml-2">
                <NavLink className="nav-link" to="#">
                  Forum
                </NavLink>
              </NavItem>
              <NavItem className="ml-2">
                <NavLink className="nav-link" to="/">
                  Help
                </NavLink>
              </NavItem>
              <NavItem className="ml-2">
                <NotificationsModal />
              </NavItem>
              <NavItem className="ml-2">
                <AccountModal />
              </NavItem>
              {/* {isAuthenticated ? authLinks : guestLinks} */}
            </Nav>
          </Collapse>
        </Navbar>
        <hr className="m-0"></hr>
        {/* <Navbar expand="sm" className="secondary-navbar">
              {secondaryNavItems}
          </Navbar>
          <hr className="m-0"></hr> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  null
)(AppNavbar);
