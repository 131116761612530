import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { withRouter } from "react-router-dom";
import { getProjects } from "../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddPropertyModal from "../../../main_actions/add_property_modal";

class StammdatenProjekteTab extends Component {
  componentDidMount() {
    this.props.getProjects();
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  projectsListItem = project => {
    return (
      <tr>
        {/* TODO: REPLACE WITH ACTUAL STATUS */}
        <td>Aktiv</td>
        <td>{project.name}</td>
      </tr>
    );
  };

  render() {
    const projects = this.props.projects;
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Immobilien</b>
                  </h5>
                </Col>
                <Col>
                  <AddPropertyModal />
                </Col>
              </Row>
              <Row>
                <Col>
                  {projects?.length ? (
                    <Table>
                      <thead>
                        <tr className="greyed-out">
                          <th>Status</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map(project => {
                          return this.projectsListItem(project);
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <ListGroup className="pt-2">
                      <ListGroupItem className="center greyed-out">
                        There are no members yet...
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col sm={4}>
						<Card>
                <h5 className="mb-4"><b>Filter:</b></h5>
                <Form>
                  <FormGroup>
                    <Label for="">Bezeichnung</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Label for="">Status</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Button className="btn-primary" style={{ marginTop: "2rem" }} block>
                      Filtern
                    </Button>
                  </FormGroup>
                </Form>
            </Card>
					</Col> */}
        </Row>
      </Container>
    );
  }
}

StammdatenProjekteTab.propTypes = {
  getProjects: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProjects }
)(withRouter(StammdatenProjekteTab));
