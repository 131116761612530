import React, { Component } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCircle,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";

import { Link } from "react-router-dom";

import { getProjects } from "../../../../../actions/projectActions";
import { getDashboardExpensesWeekly } from "../../../../../actions/dashboardActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HinzufügenModal from "./create_button/HinzufügenModal";

class DashboardPage extends Component {
  // TODO: Fill out fields and extract Cards into their own Component classes
  componentDidMount() {
    this.props.getProjects();
    //this.props.getDashboardExpensesWeekly(this.startOfWeek(new Date()), this.endOfWeek(new Date()))
  }
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }
  endOfWeek(date) {
    let lastday = date.getDate() - (date.getDay() - 1) + 4;
    return new Date(date.setDate(lastday));
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  calculateTotalBudgets = projects => {
    var totalBudgets = 0;
    projects.forEach(project => {
      totalBudgets += project.budget;
    });
    return totalBudgets;
  };

  // calculateTotalExpenses = projects => {
  //   var totalExpenses = 0;
  //   projects.forEach(project => {
  //     project.invoices.forEach(invoice => {
  //       totalExpenses += invoice.price
  //     })
  //   })
  //   return totalExpenses;
  // }

  aufgabenModuleItem = assignment => {
    return (
      <tr>
        <td>{assignment[0]}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  postfachModuleItem = notification => {
    return (
      <ListGroupItem>
        <p>{/* Notification */}</p>
      </ListGroupItem>
    );
  };

  render() {
    const { user } = this.props.auth;
    const projects = this.props.project.projects;
    const testProp = this.props.testProp;
    const { company } = this.props.company;
    const {
      expenses_weekly,
      income_weekly,
      income_by_date,
      expenses_by_date
    } = this.props.dashboard;
    // TODO: Determine how to extract RELEVANT assignments
    const assignments = projects.assignment;
    const postfach = [];
    return (
      <div className="dashboard">
        <Container className="mt-4">
          <Row>
            <Col>
              <h4 className="page-title">Dashboard</h4>
            </Col>
            <Col>
              <HinzufügenModal projects={projects} />
            </Col>
          </Row>
          <Row>
            <Col className="dashboard-projects" sm={8}>
              {projects.map(
                ({
                  id,
                  address,
                  name,
                  client,
                  end_date,
                  budget,
                  is_complete,
                  is_archived
                }) => {
                  return (
                    <Link exact to={`/myProject/${id}`}>
                      <Card className="float-left mr-4">
                        <div className="project-title">
                          <h5>
                            <b>{name}</b>
                          </h5>
                          <h5 className="greyed-out">{client}</h5>
                        </div>
                        <h5 className="greyed-out">Budget</h5>
                        <h5 className="mb-3">
                          {/* TODO: REMOVE HARDCODE, switch out for sum of budget BRUTTO kostengruppen */}
                          125.000€{/* {budget.budget_netto}€ */}
                        </h5>
                        <Row>
                          <Col>
                            {/* How much of the budget has been allocated to an auftrag */}
                            <h5 className="greyed-out">Auftragsstand</h5>
                            <h5>56%</h5>
                          </Col>
                          <Col>
                            {/* The percentage of aufträge that are already complete/paid/released */}
                            <h5 className="greyed-out">Freigabestand</h5>
                            <h5>31%</h5>
                          </Col>
                        </Row>
                      </Card>
                    </Link>
                  );
                }
              )}
            </Col>
            <Col sm={4}>
              <Row className="dashboard-aufgaben">
                <Card>
                  <Row>
                    <Col>
                      <h5>
                        <b>Aufgaben</b>
                      </h5>
                    </Col>
                    <Col>
                      <Link exact to={"/aufgaben"}>
                        <Button className="btn-white float-right">
                          Alle Aufgaben
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {assignments?.length ? (
                        <Table>
                          <thead>
                            <tr className="greyed-out">
                              <th>Beschreibung</th>
                              <th>Projekt</th>
                              <th>Frist</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assignments?.map(assignment => {
                              return this.aufgabenModuleItem(assignment);
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <ListGroup className="pt-2">
                          <ListGroupItem className="center greyed-out">
                            You have not aufgaben at this time...
                          </ListGroupItem>
                        </ListGroup>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row className="dashboard-postfach">
                <Card>
                  <Row>
                    <Col>
                      <h5>
                        <b>Postfach</b>
                      </h5>
                    </Col>
                    <Col>
                      <Link exact to={"/postfach"}>
                        <Button className="btn-white float-right">
                          Postfach öffnen
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ListGroup className="pt-2">
                        {/* {postfach?.length ?
                          postfach?.map((notification) => {
                            return this.aufgabenModuleItem(notification)
                          }) :
                          <ListGroupItem className="center greyed-out">
                            You have no updates at this time...
                          </ListGroupItem>
                        } */}
                        <ListGroupItem
                          className="project-dashboard-notification mb-2"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <Row>
                            <Col sm={2}>
                              <Avatar
                                className="float-right mt-2"
                                src="../../assets/DefaultProfilePic.svg"
                              />
                            </Col>
                            <Col sm={7}>
                              <Row>
                                <p className="path">
                                  Factory Berlin > Corkovic GmbH > Rechnung
                                </p>
                              </Row>
                              <Row>
                                <p>
                                  Lukas Esser
                                  <b> hat eine Rechnung hochgeladen</b>
                                </p>
                                <p className="greyed-out">Vor 49 Minuten</p>
                              </Row>
                            </Col>
                            <Col sm={3}>
                              <a className="link float-right" href="#">
                                Ansehen
                              </a>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col sm="4">
              <Card>
                <h4 className="mb-4 module-title">Diese Woche</h4>
                <Row>
                  <Col sm="5">
                    <div className="positive-icon">
                      <FontAwesomeIcon icon={faArrowUp} />
                      <p>{testProp}</p>
                    </div>
                  </Col>
                  <Col className="module-figure" sm="7">
                    <Row>
                      <h4>{income_weekly}€</h4>
                    </Row>
                    <Row>
                      <p className="greyed-out">Einnahmen</p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <div className="negative-icon">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  </Col>
                  <Col className="module-figure" sm="7">
                    <Row>
                      <h4>
                        {expenses_weekly}€
                      </h4>
                    </Row>
                    <Row>
                      <p className="greyed-out">Ausgaben</p>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Row>
                <Col>
                  <Card>
                    <Row className="news-card">
                      <Col sm="4" className="center-vertically">
                        <h4 className="num-of-news">{ company ? company.inbox.length : 0}</h4>
                      </Col>
                      <Col sm="8" className="center-vertically">
                        <h4>
                          Neuigkeiten{" "}
                          TODO: Implement button that leads to Notifications Page
                          <FontAwesomeIcon
                            className="ml-4"
                            icon={faChevronRight}
                          />
                        </h4>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm="8">
              <Card>
                <Row>
                  <Col sm="6">
                    <h4 className="module-title mb-4">Einnahmen & Ausgaben in €</h4>
                  </Col>
                  <Col>
                    <p>
                      <FontAwesomeIcon icon={faCircle} /> Einnahmen
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faCircle} /> Ausgaben
                    </p>
                  </Col>
                  <Col>
                    <p>*Scope Selector*</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="greyed-out">*Coming Soon*</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h4 className="module-title mb-4">Umsatz in €</h4>
                  </Col>
                  <Col>
                    <p className="greyed-out">*Date From* to *Day Until*</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="greyed-out">*Coming Soon*</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card>
                <h4 className="mb-4 module-title">Überblick</h4>
                <Row>
                  <Col>
                    <p>
                      <strong>Aktuell</strong>
                    </p>
                    <Row className="text-center">
                      <Col className="module-figure">
                        <p className="greyed-out">Baustellen</p>
                        <h4>{ projects ? `${projects.length}`: 0}</h4>
                      </Col>
                      <Col className="module-figure">
                        <p className="greyed-out">Offene Rechnungen</p>
                        <h4>0</h4>
                      </Col>
                      <Col className="module-figure">
                        <p className="greyed-out">Handwerker</p>
                        <h4>0</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      <strong>Insgesamt</strong>
                    </p>
                    <Row className="text-center">
                      <Col className="module-figure">
                        <p className="greyed-out">Baustellen</p>
                        <h4>0</h4>
                      </Col>
                      <Col className="module-figure">
                        <p className="greyed-out">Rechnungen</p>
                        <h4>0</h4>
                      </Col>
                      <Col className="module-figure">
                        <p className="greyed-out">Handwerker</p>
                        <h4>0</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// export default connect(
//   mapStateToProps,
//   null
// )(DashboardPage);

DashboardPage.propTypes = {
  getProjects: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  isArchived: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.project,
  company: state.company,
  dashboard: state.dashboard
});

export default connect(
  mapStateToProps,
  { getProjects, getDashboardExpensesWeekly }
)(DashboardPage);
