import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import Avatar from "@material-ui/core/Avatar";

class PostfachPage extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  inboxItem = (creater, type) => {
    switch (type) {
      case "NEW_INVOICE":
        return (
          <ListGroupItem className="project-dashboard-notification mb-2">
            <Row>
              <Col sm={2}>
                {creater.avatar ? (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                ) : (
                  <Avatar
                    className="float-right mt-2"
                    src="../../assets/DefaultProfilePic.svg"
                  />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">
                    Factory Berlin > Corkovic GmbH > Rechnung
                  </p>
                </Row>
                <Row>
                  <p>
                    <b>{creater} hat eine Rechnung hochgeladen</b>
                  </p>
                  <p className="greyed-out">
                    <span>&nbsp;</span>Vor 49 Minuten
                  </p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </ListGroupItem>
          // <Button>{creater} hat eine Rechnung hochgeladen</Button>
        );
      default:
        break;
    }
  };

  render() {
    const project = this.props.project;

    return (
      <div className="projects">
        <Container className="my-4">
          <h4 className="page-title">Notification center</h4>
          <h4 className="greyed-out mb-5">Alle Projekte</h4>
          <Row>
            <Col sm={9}>
              <ListGroup className="">
                {/* {project.inbox.map((item) => {
                return this.inboxItem(item.creater, item.type);
                })} */}
                <ListGroupItem className="project-dashboard-notification mb-2">
                  <Row>
                    <Col sm={2}>
                      <Avatar
                        className="float-right mt-2"
                        src="../../assets/DefaultProfilePic.svg"
                      />
                    </Col>
                    <Col sm={7}>
                      <Row>
                        <p className="path">
                          Factory Berlin > Corkovic GmbH > Rechnung
                        </p>
                      </Row>
                      <Row>
                        <p>
                          Lukas Esser<b> hat eine Rechnung hochgeladen</b>
                        </p>
                        <p className="greyed-out">
                          <span>&nbsp;</span>Vor 49 Minuten
                        </p>
                      </Row>
                    </Col>
                    <Col sm={3}>
                      <a className="link float-right" href="#">
                        Ansehen
                      </a>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Col>
            {/* TODO: Move Filter component to ProjectsList */}
            <Col sm={3}>
              <Card>
                <h5 className="mb-4">
                  <b>Filter:</b>
                </h5>
                <Form>
                  <FormGroup>
                    <Label for="">Bezeichnung</Label>
                    <Input className="mb-3" type="select" name="" id="">
                      <option value="" disabled selected>
                        Auswählen...
                      </option>
                      <option>Geschäftsführer</option>
                    </Input>
                    <Label for="">Kategorie</Label>
                    <Input className="mb-3" type="select" name="" id="">
                      <option value="" disabled selected>
                        Auswählen...
                      </option>
                      <option>Geschäftsführer</option>
                    </Input>
                    <Button
                      className="btn-primary"
                      style={{ marginTop: "2rem" }}
                      block
                    >
                      Filtern
                    </Button>
                  </FormGroup>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

PostfachPage.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(PostfachPage));
