import React, { Component } from "react";
import {
  Container,
  Badge,
  Table,
  Button,
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddNachtragModal from "./AddNachtragModal";

class ProjectNachträgeTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  // Get the right fields in (backend & frontend)
  nachtragListItem = nachtrag => {
    return (
      // TODO: Translate to english variable names
      <tr>
        <td>{nachtrag.status}</td>
        <td>{nachtrag.designation}</td>
        <td>{nachtrag.nachtragsforderung}</td>
        <td>{nachtrag.anerkannte_nachtragsforderung}</td>
        <td>{nachtrag.nachtragsart}</td>
        <td>{nachtrag.grund}</td>
        <td>{nachtrag.auftrag}</td>
      </tr>
    );
  };

  render() {
    const project = this.props.project;
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col sm={9}>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Nachträge</b>
                  </h5>
                </Col>
                <Col>
                  <AddNachtragModal />
                  <Button className="btn-white float-right">Exportieren</Button>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <thead>
                    <tr className="greyed-out">
                      <th>Nachtragsstatus</th>
                      <th>Bezeichnung</th>
                      <th>Nachtragsforderung</th>
                      <th>Anerkannte Nachtragsforderung</th>
                      <th>Nachtragsart</th>
                      <th>Grund</th>
                      <th>Auftrag</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.nachträge?.map(nachtrag => {
                      return this.auftragListItem(nachtrag);
                    })}
                    <tr
                      className="nachtrag-list-item"
                      onClick={() => {
                        this.props.switchTab("10");
                      }}
                    >
                      {/* TODO: Finish VE badges */}
                      <td>
                        <Badge className={"status-col "}>Offen</Badge>
                      </td>
                      <td>Grundstück</td>
                      <td>99.670,00 €</td>
                      <td>43.000,50 €</td>
                      <td>§2, Abs. 8, VOB/B</td>
                      <td>Hallener-weiterung</td>
                      <td>Gerüstbau Klose</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
          <Col sm={3}>
            <Card>
              <h5 className="mb-4">
                <b>Filter:</b>
              </h5>
              <Form>
                <FormGroup>
                  <Label for="">Bezeichnung</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Label for="">Status</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Button
                    className="btn-primary"
                    style={{ marginTop: "2rem" }}
                    block
                  >
                    Filtern
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* TODO: Add "Invoice Type" field to Invoice object */}

        {/* <ListGroup className="mt-3">
          {project.invoices.map(invoice => {
						if (invoice.type == "nachträge"){
            return (
              <ListGroupItem className="justify-content-between invoice-list mb-2">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={
                    "mr-2 icon-check " +
                    (this.getInvoiceIfPaid(invoice) ? "paid" : "unpaid")
                  }
                />
                {invoice.invoice_name}
                <span className="projectlist-float-right">
                  <span className="mr-5">
                    <span className="greyed-out">von:</span> {invoice.biller}
                  </span>
                  <Button className="">Herunterladen</Button>
                  <span className="projectlist-date mr-3">
                    {invoice.deadline_date.split("T")[0]}
                  </span>
                  <div className="more-info-button">
                    <ProjectInvoiceModal project={project} invoice={invoice} />
                  </div>
                </span>
              </ListGroupItem>
            );}
          })}
        </ListGroup> */}
      </Container>
    );
  }
}

ProjectNachträgeTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectNachträgeTab));
