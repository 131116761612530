import React, { Component } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Table
} from "reactstrap";
import { connect } from "react-redux";
import { addCraftsman } from "../../../../../../../../../actions/projectActions";

import Swal from "sweetalert2";

class EditKostengruppeModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // TODO: Implement for-loop for Form 'id' & functionality
  subkostengruppeItem = subkostengruppe => {
    return (
      <tr>
        <td className="pl-5">{subkostengruppe.name}</td>
        <td>
          <InputGroup>
            <Input
              className="input-labelled"
              type="text"
              name="kgBudgetNetto1"
              id="kostengruppe"
              value={subkostengruppe.budget_netto}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>in %</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            <Input
              className="input-labelled"
              type="text"
              name="kgUst1"
              id="kostengruppe"
              value={subkostengruppe.ust}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>in %</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            <Input
              className="input-labelled"
              type="text"
              name="kgBudgetBrutto1"
              id="kostengruppe"
              value={subkostengruppe.budget_brutto}
              disabled
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>in %</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </td>
      </tr>
    );
  };

  render() {
    const kostengruppe = this.props.kostengruppe;
    return (
      <div>
        <Button className="btn-white float-right" onClick={this.toggle}>
          {/* <div className="button-icon">
            <NeueBaustelleSvg />
          </div> */}
          Edit
        </Button>
        <Modal
          className=""
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader className="modal-title" toggle={this.toggle}>
            {/* <div className="header-icon">
              <NeueBaustelleSvg />
            </div> */}
            Edit Kostengruppe
          </ModalHeader>
          <ModalBody>
            <Form>
              <Table className="subk-table">
                <thead>
                  <tr className="greyed-out">
                    <th>Kostengruppe</th>
                    <th>Aktuelles Budget (Netto)</th>
                    <th>USt.</th>
                    <th>Aktuelles Budget (Brutto)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>{kostengruppe.name}</b>
                    </td>
                    <td>
                      <InputGroup>
                        <Input
                          className="input-labelled"
                          type="text"
                          name="kgBudgetNetto1"
                          id="kostengruppe"
                          value={kostengruppe.budget_netto}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>in %</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Input
                          className="input-labelled"
                          type="text"
                          name="kgUst1"
                          id="kostengruppe"
                          value={kostengruppe.ust}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>in %</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Input
                          className="input-labelled"
                          type="text"
                          name="kgBudgetBrutto1"
                          id="kostengruppe"
                          value={kostengruppe.budget_brutto}
                          disabled
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>in %</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                  </tr>
                  {/* TODO: Iterate over subkostengruppen */}
                  {kostengruppe.subkostengruppen?.map(subkostengruppe => {
                    return this.subkostengruppeItem(subkostengruppe);
                  })}
                </tbody>
              </Table>
              <Button className="float-right">Save</Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman }
)(EditKostengruppeModal);
