import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../actions/projectActions";
import { getCompanyFromUser } from "../../../../../../actions/companyActions";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddCompanyMemberModal from "../../user/accountSettings/tabs/company/AddCompanyMemberModal";

class StammdatenUsersTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
    this.props.getCompanyFromUser();
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  render() {
    const project = this.props.project;
    const members = [];
    const company = this.props.company.company;

    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Benutzer</b>
                  </h5>
                </Col>
                <Col>
                  <AddCompanyMemberModal />
                </Col>
              </Row>
              <Row>
                <Col>
                  {company.members?.length ? (
                    <Table>
                      <thead>
                        <tr className="greyed-out">
                          <th>Status</th>
                          <th>Name</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {company.members.map(member => {
                          return (
                            <tr>
                              <td>{member.status ? member.status : "None"}</td>
                              <td>{member.name ? member.name : "None"}</td>
                              <td>{member.role ? member.role : "None"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <ListGroup className="pt-2">
                      <ListGroupItem className="center greyed-out">
                        There are no members yet...
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col sm={4}>
						<Card>
                <h5 className="mb-4"><b>Filter:</b></h5>
                <Form>
                  <FormGroup>
                    <Label for="">Bezeichnung</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Label for="">Status</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Button className="btn-primary" style={{ marginTop: "2rem" }} block>
                      Filtern
                    </Button>
                  </FormGroup>
                </Form>
            </Card>
					</Col> */}
        </Row>
      </Container>
    );
  }
}

StammdatenUsersTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  getCompanyFromUser: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project,
  company: state.company
});

export default connect(
  mapStateToProps,
  { getProject, getCompanyFromUser }
)(withRouter(StammdatenUsersTab));
