import gql from 'graphql-tag';
 // Login user
export const LOGIN = gql`
  mutation login ($email: String!, $password: String!) {
    login (email: $email, password: $password) {
      token
      user {
        name
        email
        role
      }
    }
  }
`;