import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { addCraftsman } from "../../../../../../../../actions/projectActions";

import Swal from "sweetalert2";

import { ReactComponent as NeueBaustelleSvg } from "../../../../../../../../assets/NeueBaustelle.svg";

class AddAuftragModal extends Component {
  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // Add project via addProject action
    // this.props.addCraftsman(this.props.projectId, this.state.name, this.state.email);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Handwerker hinzufügt",
      text:
        "Der Handwerker " +
        this.state.name +
        " wurde der Baustelle hinzugefügt.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    return (
      <div className="hinzufügen-modal">
        <Button
          color="dark"
          className="btn-modal float-right ml-2"
          style={{ marginBottom: "2rem" }}
          block
          onClick={this.toggle}
        >
          <div className="button-icon">
            <NeueBaustelleSvg />
          </div>
          Hinzufügen
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            Hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Bezeichnung*</Label>
                      <Input
                        type="text"
                        name="bezeichnung"
                        id="auftrag"
                        placeholder="Bezeichnung eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Vergabeeinheit*</Label>
                      <Input
                        type="text"
                        name="vergabeeinheit"
                        id="auftrag"
                        placeholder="Vergabeeinheit eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Auftragsnummer</Label>
                      <Input
                        type="text"
                        name="auftragsnummer"
                        id="auftrag"
                        placeholder="Auftragsnummer eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Kostenstelle</Label>
                      <Input
                        type="text"
                        name="kostenstelle"
                        id="auftrag"
                        placeholder="Kostenstelle eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Auftragnehmer</Label>
                      <Input
                        type="text"
                        name="auftragnehmer"
                        id="auftrag"
                        placeholder="Auftragnehmer eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="role">Auftraggeber</Label>
                      <Input
                        type="text"
                        name="auftraggeber"
                        id="auftrag"
                        placeholder="Auftraggeber eingeben..."
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-3" form>
                  <Col>
                    <h5>Auftragsvolumen</h5>
                    <Label>Auftragstyp*</Label>
                    <Input type="select" name="auftragstyp" id="auftrag">
                      <option value="" disabled selected></option>
                      <option>Geschäftsführer</option>
                      <option>Projektsteuerer</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6>Vergabeeinheit*</h6>
                  </Col>
                </Row>
                {/* Move labels to right of input fields */}
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="end_date">(netto)</Label>
                      <Input
                        type="text"
                        name="price_netto"
                        id="nachtrag"
                        placeholder="Preis"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="linediv">
                      <Label for="end_date">USt.</Label>
                      <Input
                        type="text"
                        name="price_ust"
                        id="nachtrag"
                        placeholder="Preis"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="linediv">
                      <Label for="end_date">(brutto)</Label>
                      <Input
                        type="text"
                        name="price_brutto"
                        id="nachtrag"
                        placeholder="Preis"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3" form>
                  <Col>
                    <div className="linediv">
                      <Label for="end_date">Auftrags</Label>
                      <Input type="select" name="status" id="nachtrag">
                        <option value="" disabled selected>
                          Status auswählen...
                        </option>
                        <option>Geschäftsführer</option>
                        <option>Projektsteuerer</option>
                      </Input>
                    </div>
                  </Col>
                </Row>
                <hr />
                {/* Move labels to right of input fields */}
                <Row className="mb-3" form>
                  <Col>
                    <h5>Rückstellungen</h5>
                    <h6>Betrag*</h6>
                    <Row>
                      <Col>
                        <div className="linediv">
                          <Label for="end_date">(netto)</Label>
                          <Input
                            type="text"
                            name="price_netto"
                            id="nachtrag"
                            placeholder="Preis"
                            onChange={this.onChange}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="linediv">
                          <Label for="end_date">USt.</Label>
                          <Input
                            type="text"
                            name="price_ust"
                            id="nachtrag"
                            placeholder="Preis"
                            onChange={this.onChange}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="linediv">
                          <Label for="end_date">(brutto)</Label>
                          <Input
                            type="text"
                            name="price_brutto"
                            id="nachtrag"
                            placeholder="Preis"
                            onChange={this.onChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button
                  className=" float-right btn-modal ml-2"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Hinzufügen
                </Button>
                <Button
                  onClick={this.toggle}
                  className="btn-white float-right btn-modal"
                  color="dark"
                  style={{ marginTop: "2rem" }}
                  block
                >
                  Abbrechen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  craftsman: state.craftsman
});

export default connect(
  mapStateToProps,
  { addCraftsman }
)(AddAuftragModal);
