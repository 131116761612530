import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  NavLink
} from "reactstrap";
import { ReactComponent as NeueBaustelleSvg } from "../../../../../assets/NeueBaustelle.svg";
import { connect } from "react-redux";
import { addProject } from "../../../../../actions/projectActions";
import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHome,
//   faBriefcase,
//   faPaperPlane,
//   faQuestion,
//   faImage,
//   faCopy,
// } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

class ProjectModal extends Component {
  // TODO: ADD DOWNLOAD AN INVOICE FUNCTIONALITY

  state = {
    modal: false,
    name: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newProject = {
      name: this.state.name,
      client: this.state.client,
      address: {
        zip_code: parseInt(this.state.zip_code),
        street_address: this.state.street,
        city: this.state.city
      },
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      project_id: this.state.project_id,
      budget: this.state.budget
    };
    // Add project via addProject action
    this.props.addProject(newProject);
    // Close modal
    this.toggle();
    Swal.fire({
      title: "Baustelle erstellt",
      text:
        "Die Baustelle " +
        this.state.name +
        " wurde erstellt und befindet sich unter Ihren Baustellen.",
      icon: "success",
      confirmButtonColor: "#4a8950",
      confirmButtonText: "Ok"
    });
  };

  render() {
    let sidebarItem;
    if (this.props.sidebarIsOpen == false) {
      sidebarItem = (
        <NavLink className="" tag={Link} to={"/projects"} onClick={this.toggle}>
          <div className="svg-container float-left">
            <NeueBaustelleSvg />
          </div>
        </NavLink>
      );
    } else {
      sidebarItem = (
        <NavLink
          className="side-item"
          tag={Link}
          to={"/projects"}
          onClick={this.toggle}
        >
          <div className="svg-container float-left">
            <NeueBaustelleSvg />
          </div>
          Neues Projekt
        </NavLink>
      );
    }

    return (
      <div>
        {/* <button type="button" className="new-project-button" onClick={this.toggle}>
            <NavLink className="nav-link new-project-btn" to="/projects">Neue Baustelle</NavLink>
          
        </button> */}
        {sidebarItem}
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal-title" toggle={this.toggle}>
            <div className="header-icon">
              <NeueBaustelleSvg />
            </div>
            <span>Neue Baustelle anlegen</span>
          </ModalHeader>
          <ModalBody className="project-modal">
            <Form className="custom-form" onSubmit={this.onSubmit}>
              <FormGroup>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Auftraggeber</Label>
                      <Input
                        type="text"
                        name="client"
                        id="project"
                        placeholder="Client"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Projektstart</Label>
                      <Input
                        type="date"
                        name="start_date"
                        id="project"
                        placeholder="dd/mm/yy"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Kostenstelle</Label>
                      <Input
                        type="text"
                        name="project_id"
                        id="project"
                        placeholder="Kostenstelle eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Geplantes Ende</Label>
                      <Input
                        type="date"
                        name="end_date"
                        id="project"
                        placeholder="dd/mm/yy"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="project"
                        placeholder="Name der Baustelle"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">PLZ</Label>
                      <Input
                        type="text"
                        name="zip_code"
                        id="project"
                        placeholder="PLZ eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Anschrift</Label>
                      <Input
                        type="text"
                        name="street"
                        id="project"
                        placeholder="Straße und Hausnummer eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="project">Stadt</Label>
                      <Input
                        type="text"
                        name="city"
                        id="project"
                        placeholder="Stadt eingeben"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <div className="linediv">
                      <Label for="end_date">Umsatz</Label>
                      <Input
                        type="text"
                        name="budget"
                        id="project"
                        placeholder="Geplanter Umsatz für dieses Projekt"
                        onChange={this.onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }} block>
                  Baustelle anlegen
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { addProject }
)(ProjectModal);
