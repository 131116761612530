import React, { Component } from "react";
import {
  Container,
  Badge,
  Table,
  Label,
  Input,
  Form,
  FormGroup,
  Button,
  Row,
  Card,
  Col
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NewVergabeeinheitModal from "./NewVergabeeinheitModal";

class ProjectVergabeanheitenTab extends Component {
  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };
  status = status => {
    switch (status) {
      case 0:
        return "Geplant";
      case 1:
        return "COMMISSIONED";
      case 2:
        return "FINAL";

      default:
        break;
    }
  };
  calculateBudget = costgroups => {
    let budget = 0;
    costgroups.forEach(element => {
      budget = +element.budget_netto;
    });
    return budget;
  };
  // Get the right fields in (backend & frontend)
  vergabeeinheitListItem = vergabeeinheit => {
    return (
      <tr
        className="vergabeeinheit-list-item"
        onClick={() => {
          this.props.switchTab("8");
        }}
      >
        {/* TODO: Finish VE badges */}
        <td>
          <Badge className={"status-col "}>
            {this.status(vergabeeinheit.status)}
          </Badge>
        </td>
        <td>{vergabeeinheit.name}</td>
        <td>{this.calculateBudget(vergabeeinheit.costgroups)}</td>
      </tr>
    );
  };

  render() {
    const project = this.props.project;
    return (
      <Container className="vergabeeinheiten p-0">
        <Row className="mt-3">
          <Col sm={9}>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Vergabeeinheiten</b>
                  </h5>
                </Col>
                <Col>
                  <NewVergabeeinheitModal />
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <thead>
                    <tr className="greyed-out">
                      <th>Status</th>
                      <th>Bezeichnung</th>
                      <th>Budget (Netto)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.allocationunit?.length ? (
                      project?.allocationunit?.map(vergabeeinheit => {
                        return this.vergabeeinheitListItem(vergabeeinheit);
                      })
                    ) : (
                      <p className="greyed-out pt-3 px-3">
                        Keine vergabeeinheiten in dieser Baustelle.
                      </p>
                    )}
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
          <Col sm={3}>
            <Card>
              <h5 className="mb-4">
                <b>Filter:</b>
              </h5>
              <Form>
                <FormGroup>
                  <Label for="">Bezeichnung</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Label for="">Status</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Button
                    className="btn-primary"
                    style={{ marginTop: "2rem" }}
                    block
                  >
                    Filtern
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* TODO: Add "Invoice Type" field to Invoice object */}

        {/* <ListGroup className="mt-3">
          {project.invoices.map(invoice => {
						if (invoice.type == "nachträge"){
            return (
              <ListGroupItem className="justify-content-between invoice-list mb-2">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={
                    "mr-2 icon-check " +
                    (this.getInvoiceIfPaid(invoice) ? "paid" : "unpaid")
                  }
                />
                {invoice.invoice_name}
                <span className="projectlist-float-right">
                  <span className="mr-5">
                    <span className="greyed-out">von:</span> {invoice.biller}
                  </span>
                  <Button className="">Herunterladen</Button>
                  <span className="projectlist-date mr-3">
                    {invoice.deadline_date.split("T")[0]}
                  </span>
                  <div className="more-info-button">
                    <ProjectInvoiceModal project={project} invoice={invoice} />
                  </div>
                </span>
              </ListGroupItem>
            );}
          })}
        </ListGroup> */}
      </Container>
    );
  }
}

ProjectVergabeanheitenTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectVergabeanheitenTab));
