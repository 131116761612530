import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  Card,
  TabPane,
  TabContent,
  Row,
  Col,
  Button,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import NewTaskModal from "./NewTaskModal";

import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AufgabenPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  aufgabenModuleItem = aufgabe => {
    return (
      <tr>
        <td>{/* Beschreibung */}</td>
        <td>{/* Projekt */}</td>
        <td>{/* Frist */}</td>
      </tr>
    );
  };

  // TODO: Determine whether craftsmen, clients, and projects can be added from this page
  // TODO: Add sections for Rechnung aufgaben, etc. Rechnung aufgaben are created automatically, not manually
  render() {
    const aufgaben = [];
    return (
      <div>
        <Container className="my-4">
          <h4 className="page-title mb-5">Aufgaben</h4>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <h5>
                      <b>Aufgaben:</b>
                    </h5>
                  </Col>
                  <Col>
                    <NewTaskModal />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {aufgaben?.length ? (
                      <Table>
                        <thead>
                          <tr className="greyed-out">
                            <th>Beschreibung</th>
                            <th>Projekt</th>
                            <th>Frist</th>
                          </tr>
                        </thead>
                        <tbody>
                          {aufgaben?.map(aufgabe => {
                            return this.aufgabenModuleItem(aufgabe);
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <ListGroup className="pt-2">
                        <ListGroupItem className="center greyed-out">
                          Du hast bisher noch keine Aufgaben...
                        </ListGroupItem>
                      </ListGroup>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AufgabenPage;
