import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  TabPane,
  TabContent
} from "reactstrap";
import ProfileTab from "./tabs/ProfileTab";
import NotificationsTab from "./tabs/NotificationsTab";
import CompanyTab from "./tabs/company/CompanyTab";

import classnames from "classnames";

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  render() {
    // const project = this.props.project;
    // TODO: Add "Privacy" & "Billing & Payments" tabs
    return (
      <div>
        <Container className="my-4">
          {/* <h4 className="page-title">{project ? project.name : "None"}</h4> */}
          <h4 className="page-title">Einstellungen</h4>
        </Container>
        <Container>
          <Nav tabs className="project-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Profil
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Unternehmen
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Notifications
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 1 ? <ProfileTab /> : null}
            </TabPane>
            <TabPane tabId="2">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 2 ? <CompanyTab /> : null}
            </TabPane>
            <TabPane tabId="3">
              {/* eslint-disable-next-line */}
              {this.state.activeTab == 3 ? <NotificationsTab /> : null}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

export default SettingsPage;
