import React, { Component } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Table
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddInvoiceModal from "../../AddInvoiceModal";

class ProjectInvoicesTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  // Get the right fields in (backend & frontend)
  invoiceListItem = invoice => {
    return (
      <tr
        className="rechnung-list-item"
        onClick={() => {
          this.props.switchTab("11");
        }}
      >
        <td>{invoice.status}</td>
        <td></td>
        <td>{invoice.biller}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{invoice.issue_date}</td>
        <td></td>
      </tr>
    );
  };

  render() {
    const project = this.props.project;
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col sm={9}>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Rechnungen</b>
                  </h5>
                </Col>
                <Col>
                  <AddInvoiceModal />
                  <Button className="btn-white float-right">Exportieren</Button>
                </Col>
              </Row>
              {/* TODO: Implement side scrolling section for invoices table */}
              <Row>
                <Col>
                  {project?.invoices?.length ? (
                    <Table className="invoices-table">
                      <thead>
                        <tr className="greyed-out">
                          <th>Status</th>
                          <th>Id</th>
                          <th>Auftragnehmer</th>
                          <th>Auftrag</th>
                          <th>Rechnungs-Typ</th>
                          <th>Rechnungsnummer</th>
                          <th>Rechnungsdatum</th>
                          <th>Eingangsdatum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {project?.invoices?.map(invoice => {
                          return this.invoiceListItem(invoice);
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <ListGroup className="pt-2">
                      <ListGroupItem className="center greyed-out">
                        You have not aufgaben at this time...
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={3}>
            <Card>
              <h5 className="mb-4">
                <b>Filter:</b>
              </h5>
              <Form>
                <FormGroup>
                  <Label for="">Bezeichnung</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Label for="">Status</Label>
                  <Input className="mb-3" type="select" name="" id="">
                    <option value="" disabled selected>
                      Auswählen...
                    </option>
                    <option>Geschäftsführer</option>
                  </Input>
                  <Button
                    className="btn-primary"
                    style={{ marginTop: "2rem" }}
                    block
                  >
                    Filtern
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* <ListGroup className="mt-3">
          {project.invoices.map(invoice => {
            return (
              <ListGroupItem className="justify-content-between invoice-list mb-2">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={
                    "mr-2 icon-check " +
                    (this.getInvoiceIfPaid(invoice) ? "paid" : "unpaid")
                  }
                />
                {invoice.invoice_name}
                <span className="projectlist-float-right">
                  <span className="mr-5">
                    <span className="greyed-out">von:</span> {invoice.biller}
                  </span>
                  <Button className="">Herunterladen</Button>
                  <span className="projectlist-date mr-3">
                    {invoice.deadline_date.split("T")[0]}
                  </span>
                  <div className="more-info-button">
                    <ProjectInvoiceModal project={project} invoice={invoice} />
                  </div>
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
        <InvoiceModal projectId={this.props.match.params.id} /> */}
      </Container>
    );
  }
}

ProjectInvoicesTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectInvoicesTab));
