import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddClientModal from "../../../main_actions/add_client_modal";

class StammdatenClientsTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  getAllClients = projects => {
    const allClients = [];

    projects.forEach(project => {
      allClients.push(project.client);
    });
  };

  clientsListItem = client => {
    return (
      <tr>
        <td>{client.name}</td>
      </tr>
    );
  };

  render() {
    const projects = this.props.projects;
    const clients = this.getAllClients(projects);
    const project = this.props.project;
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Auftraggeber</b>
                  </h5>
                </Col>
                <Col>
                  <AddClientModal />
                </Col>
              </Row>
              <Row>
                <Col>
                  {clients?.length ? (
                    <Table>
                      <thead>
                        <tr className="greyed-out">
                          <th>Status</th>
                          <th>Name</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map(client => {
                          return this.clientsListItem(client);
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <ListGroup className="pt-2">
                      <ListGroupItem className="center greyed-out">
                        There are no clients yet...
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col sm={4}>
						<Card>
                <h5 className="mb-4"><b>Filter:</b></h5>
                <Form>
                  <FormGroup>
                    <Label for="">Bezeichnung</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Label for="">Status</Label>
                    <Input className="mb-3" type="select" name="" id="">
											<option value="" disabled selected>Auswählen...</option>
         			 				<option>Geschäftsführer</option>
        						</Input>
                    <Button className="btn-primary" style={{ marginTop: "2rem" }} block>
                      Filtern
                    </Button>
                  </FormGroup>
                </Form>
            </Card>
					</Col> */}
        </Row>
      </Container>
    );
  }
}

StammdatenClientsTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(StammdatenClientsTab));
