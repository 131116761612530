import gql from "graphql-tag";
// Register user
export const REGISTER = gql`
  mutation register($name: String!, $email: String!, $password: String!) {
    register(name: $name, email: $email, password: $password) {
      token
      user {
        name
        email
        password
      }
    }
  }
`;
