import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { Button } from "reactstrap";

class KostengruppenList extends Component {
  render() {
    return (
      <div>
        <Button onClick={() => this.props.addKostengruppe("100", "Grundstück")}>
          100 Grundstück
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("110", "Grundstückswert")}
        >
          110 Grundstückswert
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("120", "Grundstücksnebenkosten")
          }
        >
          120 Grundstücksnebenkosten
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("130", "Rechte Dritter")}
        >
          130 Rechte Dritter
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "200",
              "Vorbereitende Maßnahmen (Herrichten + Erschließen)"
            )
          }
        >
          200 Vorbereitende Maßnahmen (Herrichten + Erschließen)
        </Button>
        <Button onClick={() => this.props.addKostengruppe("210", "Herrichten")}>
          210 Herrichten
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("220", "Öffentliche Erschließung")
          }
        >
          220 Öffentliche Erschließung
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("230", "Nichtöffentliche Erschließung")
          }
        >
          230 Nichtöffentliche Erschließung
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("240", "Ausgleichsabgaben")}
        >
          240 Ausgleichsabgaben
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("250", "Übergangsmaßnahmen")
          }
        >
          250 Übergangsmaßnahmen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("300", "Bauwerk - Baukonstruktionen")
          }
        >
          300 Bauwerk - Baukonstruktionen
        </Button>
        <Button onClick={() => this.props.addKostengruppe("310", "Baugrube")}>
          310 Baugrube
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("320", "Gründung, Unterbau")
          }
        >
          320 Gründung, Unterbau
        </Button>
        <Button onClick={() => this.props.addKostengruppe("330", "Außenwände")}>
          330 Außenwände
        </Button>
        <Button onClick={() => this.props.addKostengruppe("340", "Innenwände")}>
          340 Innenwände
        </Button>
        <Button onClick={() => this.props.addKostengruppe("350", "Decken")}>
          350 Decken
        </Button>
        <Button onClick={() => this.props.addKostengruppe("360", "Dächer")}>
          360 Dächer
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("370", "Infrastrukturanlagen")
          }
        >
          370 Infrastrukturanlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("380", "Baukonstruktive Einbauten")
          }
        >
          380 Baukonstruktive Einbauten
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "390",
              "Sonstige Maßnahmen für Baukonstruktionen"
            )
          }
        >
          390 Sonstige Maßnahmen für Baukonstruktionen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("400", "Bauwerk - Technische Anlagen")
          }
        >
          400 Bauwerk - Technische Anlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("410", "Abwasser-, Wasser-, Gasanlagen")
          }
        >
          410 Abwasser-, Wasser-, Gasanlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("420", "Wärmeversorgungsanlagen")
          }
        >
          420 Wärmeversorgungsanlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("430", "Raumlufttechnische Anlagen")
          }
        >
          430 Raumlufttechnische Anlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("440", "Elektrische Anlagen")
          }
        >
          440 Elektrische Anlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "450",
              "Kommunikations., sicherheits- und informationstechnische Anlagen"
            )
          }
        >
          450 Kommunikations., sicherheits- und informationstechnische Anlagen
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("460", "Förderanlagen")}
        >
          460 Förderanlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("470", "Nutzungsspezifische Anlagen")
          }
        >
          470 Nutzungsspezifische Anlagen
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("480", "Gebäudeautomation")}
        >
          480 Gebäudeautomation
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "490",
              "Sonstige Maßnahmen für technische Anlagen"
            )
          }
        >
          490 Sonstige Maßnahmen für technische Anlagen
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("500", "Außenanlagen")}
        >
          500 Außenanlagen
        </Button>
        <Button onClick={() => this.props.addKostengruppe("510", "Erdbau")}>
          510 Erdbau
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("520", "Gründung, Unterbau")
          }
        >
          520 Gründung, Unterbau
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("530", "Oberbau, Deckschichten")
          }
        >
          530 Oberbau, Deckschichten
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("540", "Baukonstruktionen")}
        >
          540 Baukonstruktionen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("550", "Technische Anlagen")
          }
        >
          550 Technische Anlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "560",
              "Einbauten in Außenanlagen und Freiflächen"
            )
          }
        >
          560 Einbauten in Außenanlagen und Freiflächen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("570", "Vegetationsflächen")
          }
        >
          570 Vegetationsflächen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("590", "Sonstige Außenanlagen")
          }
        >
          590 Sonstige Außenanlagen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("600", "Ausstattung und Kunstwerke")
          }
        >
          600 Ausstattung und Kunstwerke
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("610", "Allgemeine Ausstattung")
          }
        >
          610 Allgemeine Ausstattung
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("620", "Besondere Austattung")
          }
        >
          620 Besondere Austattung
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe(
              "630",
              "Informationstechnische Ausstattung"
            )
          }
        >
          630 Informationstechnische Ausstattung
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("640", "Kunstobjekte")}
        >
          640 Kunstobjekte
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("690", "Sonstige Ausstattung")
          }
        >
          690 Sonstige Ausstattung
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("700", "Baunebenkosten")}
        >
          700 Baunebenkosten
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("710", "Bauherrenaufgaben")}
        >
          710 Bauherrenaufgaben
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("720", "Vorbereitung der Objektplanung")
          }
        >
          720 Vorbereitung der Objektplanung
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("730", "Objektplanung")}
        >
          730 Objektplanung
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("740", "Fachplanung")}
        >
          740 Fachplanung
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("750", "Künstlerische Leistungen")
          }
        >
          750 Künstlerische Leistungen
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("760", "Allgemeine Baunebenkosten")
          }
        >
          760 Allgemeine Baunebenkosten
        </Button>
        <Button
          onClick={() =>
            this.props.addKostengruppe("790", "Sonstige Baunebenkosten")
          }
        >
          790 Sonstige Baunebenkosten
        </Button>
        <Button
          onClick={() => this.props.addKostengruppe("800", "Finanzierung")}
        >
          800 Finanzierung
        </Button>
      </div>
      // TODO: Convert to MaterialUI Virtualized List
      // <List
      // component="nav"
      // aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
      // className={classes.root}
      // >
      // <ListItem button onClick={() => this.props.addKostengruppe("100 Grundstück")}
      // TODO: Implement funcionality
      // onClick={selectKostengruppe(100)}
      // >
      //   <ListItemText primary="100 Grundstück" />
      // </ListItem>
      // <ListItem button onClick={() => this.props.addKostengruppe("110 Grundstückswert")}>
      //   <ListItemText primary="110 Grundstückswert" />
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="120 Grundstücksnebenkosten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      /* <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="121 Vermessungsgebühren" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="122 Gerichtsgebühren" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="123 Notargebühren" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="124 Grunderwerbsteuer" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="125 Untersuchungen" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="126 Wertermittlungen" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="127 Genehmigungsgebühren" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="128 Bodenordnung" />
          </ListItem>
					<ListItem button 
					// className={classes.nested}
					>
            <ListItemText primary="129 Sonstiges zur KG 120" />
          </ListItem>
        </List>
      </Collapse> */

      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="130 Rechte Dritter" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="200 Vorbereitende Maßnahmen (Herrichten + Erschließen)" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="210 Herrichten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="220 Öffentliche Erschließung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="230 Nichtöffentliche Erschließung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="240 Ausgleichsabgaben" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="250 Übergangsmaßnahmen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="300 Bauwerk - Baukonstruktionen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="310 Baugrube" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      /* TODO: Finish Kostengruppen */
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="320 Gründung, Unterbau" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="330 Außenwände" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="340 Innenwände" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="350 Decken" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="360 Dächer" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="370 Infrastrukturanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="380 Baukonstruktive Einbauten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="390 Sonstige Maßnahmen für Baukonstruktionen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="400 Bauwerk - Technische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="410 Abwasser-, Wasser-, Gasanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="420 Wärmeversorgungsanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="430 Raumlufttechnische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="440 Elektrische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="450 Kommunikations., sicherheits- und informationstechnische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="460 Förderanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="470 Nutzungsspezifische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="480 Gebäudeautomation" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="490 Sonstige Maßnahmen für technische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="500 Außenanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="510 Erdbau" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="520 Gründung, Unterbau" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="530 Oberbau, Deckschichten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="540 Baukonstruktionen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="550 Technische Anlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="560 Einbauten in Außenanlagen und Freiflächen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="570 Vegetationsflächen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="590 Sonstige Außenanlagen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="600 Ausstattung und Kunstwerke" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="610 Allgemeine Ausstattung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="620 Besondere Austattung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="630 Informationstechnische Ausstattung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="640 Kunstobjekte" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="690 Sonstige Ausstattung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="700 Baunebenkosten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="720 Vorbereitung der Objektplanung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="730 Objektplanung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="740 Fachplanung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="750 Künstlerische Leistungen" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="760 Allgemeine Baunebenkosten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="790 Sonstige Baunebenkosten" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // <ListItem button onClick={this.toggle}>
      //   <ListItemText primary="800 Finanzierung" />
      //   {this.state.isOpen ? <ExpandLess /> : <ExpandMore />}
      // </ListItem>
      // </List>
    );
  }
}

export default KostengruppenList;
