import React, { Component, Fragment } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../../actions/authActions";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import history from "../../helper/history";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  logout = () => {
    Swal.fire({
      title: "Ausloggen",
      text:
        "Sind Sie sicher, dass Sie sich aus ihrem Bautory Account ausloggen möchten?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#14337e",
      cancelButtonColor: "#979797",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja"
    }).then(result => {
      if (result.value) {
        this.props.logout();
        history.push("/login");
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Button id="btn-logout" onClick={this.logout}>
          <Row>
            <Col sm={1}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </Col>
            <Col>Ausloggen</Col>
          </Row>
        </Button>
      </Fragment>
    );
  }
}

export default connect(
  null,
  { logout }
)(Logout);
