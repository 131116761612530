import React, { Component } from "react";
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Card,
  Table
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddKostengruppeModal from "./kostengruppen/AddKostengruppeModal";
import EditKostengruppeModal from "./kostengruppen/EditKostengruppeModal";

class ProjectBudgetTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  kostengruppeTableRow = kostengruppe => {
    return (
      <tr>
        <td>{kostengruppe?.name}</td>
        <td>
          <p className="project-budget-field">
            {/* {kostengruppe?.budget_netto} <span className="float-right">in €</span> */}
            <InputGroup>
              <Input
                className="input-labelled"
                type="text"
                name="kgBudgetNetto1"
                id="kostengruppe"
                value={kostengruppe.budget_netto}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>in €</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </p>
        </td>
        <td>
          <p className="project-budget-field">
            {/* {kostengruppe?.ust} <span className="float-right">in %</span> */}
            <InputGroup>
              <Input
                className="input-labelled"
                type="text"
                name="kgUst1"
                id="kostengruppe"
                value={kostengruppe.ust}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>in %</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </p>
        </td>
        <td>
          <Row>
            <Col>
              <p className="project-budget-field">
                {/* {kostengruppe?.budget_brutto} <span className="float-right">in €</span> */}
                <InputGroup>
                  <Input
                    className="input-labelled"
                    type="text"
                    name="kgBudgetBrutto1"
                    id="kostengruppe"
                    value={kostengruppe.budget_brutto}
                    disabled
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>in %</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </p>
            </Col>
            <Col>
              <EditKostengruppeModal kostengruppe={kostengruppe} />
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  calcKostengruppenBudgetNetto = kostengruppen => {
    var totalBudgetNetto = 0;
    kostengruppen.forEach(kostengruppe => {
      totalBudgetNetto += kostengruppe.budget_netto;
    });
    return totalBudgetNetto;
  };

  calcKostengruppenBudgetBrutto = kostengruppen => {
    var totalBudgetBrutto = 0;
    kostengruppen.forEach(kostengruppe => {
      totalBudgetBrutto += kostengruppe.budget_brutto;
    });
    return totalBudgetBrutto;
  };

  getSubkostengruppen = kostengruppe => {
    switch (kostengruppe) {
      case "100 Grundstück":
        return {};
      case "110 Grundstückswert":
        return {};
      case "120 Grundstücksnebenkosten":
        return [
          "121 Vermessungsgebühren",
          "122 Gerichtsgebühren",
          "123 Notargebühren",
          "124	Grunderwerbsteuer",
          "125	Untersuchungen",
          "126	Wertermittlungen",
          "127	Genehmigungsgebühren",
          "128	Bodenordnung",
          "129	Sonstiges zur KG 120"
        ];
      case "130 Rechte Dritter":
        return [
          "131	Abfindungen",
          "132	Ablösen dringlicher Rechte",
          "139	Sonstiges zur KG 130"
        ];
      case "200 Vorbereitende Maßnahmen (Herrichten + Erschließen)":
        return [];
      case "210 Herrichten":
        return [
          "211	Sicherungsmaßnahmen",
          "212	Abbruchmaßnahmen",
          "213	Altlastenbeseitigung",
          "214	Herrichten der Geländeoberfläche",
          "215	Kampfmittelräumung",
          "216	Kulturhistorische Funde",
          "219	Sonstiges zu KG 210"
        ];
      case "220 Öffentliche Erschließung":
        return [
          "221	Abwasserentsorgung",
          "222	Wasserversorgung",
          "223	Gasversorgung",
          "224	Fernwärmeversorgung",
          "225	Stromversorgung",
          "226	Telekommunikation",
          "227	Verkehrserschließung",
          "228	Abfallentsorgung",
          "229	Sonstiges zu KG 220"
        ];
      case "230 Nichtöffentliche Erschließung":
        return [];
      case "240 Ausgleichsabgaben":
        return [
          "241	Ausgleichsmaßnahmen",
          "241	Ausgmeichsabgaben",
          "249	Sonstiges zur KG 240"
        ];
      case "250 Übergangsmaßnahmen":
        return [
          "251	Provisorien",
          "252	Auslagerungen",
          "259	Sonstiges zur KG 240"
        ];
      case "300 Bauwerk - Baukonstruktionen":
        return [];
      case "310 Baugrube":
        return [
          "311	Baugrubenherstellung",
          "312	Baugrubenumschließung",
          "313	Wasserhaltung",
          "314	Vortrieb",
          "319	Sonstiges zu KG 310"
        ];
      case "320 Gründung, Unterbau":
        return [
          "321	Baugrundverbesserung",
          "322	Flachgründungen",
          "323	Tiefgründungen",
          "324	Gründungsbeläge",
          "325	Abdichtungen und Bekleidungen",
          "326	Dränagen",
          "329	Sonstiges zu KG 320"
        ];
      case "330 Außenwände":
        return [
          "331	Tragende Außenwände",
          "332	Nichttragende Außenwände",
          "333	Außenstützen",
          "334	Außentüren und –fenster",
          "335	Außenwandbekleidungen, außen",
          "336	Außenwandbekleidungen, innen",
          "337	Elementierte Außenwände",
          "338	Lichtschutz",
          "339	Sonstiges zu KG 330"
        ];
      case "340 Innenwände":
        return [
          "341	Nichttragende Innenwände",
          "342	Tragende Innenwände",
          "343	Innenstützen",
          "344	Innentüren und –fenster",
          "345	Innenwandbekleidungen",
          "346	Elementierte Innenwände",
          "347	Lichtschutz",
          "349	Sonstiges zu KG 340"
        ];
      case "350 Decken":
        return [
          "351	Deckenkonstruktionen",
          "352	Deckenöffnungen",
          "353	Deckenbeläge",
          "354	Deckenbekleidungen",
          "355	Elementierte Deckenkonstruktionen",
          "359	Sonstiges zu KG 350"
        ];
      case "360 Dächer":
        return [
          "361	Dachkonstruktionen",
          "362	Dachfenster, Dachöffnungen",
          "363	Dachbeläge",
          "364	Dachbekleidungen",
          "365	Elementierte Dachkonstruktionen",
          "366	Lichtschutz",
          "369	Sonstiges zu KG 360"
        ];
      case "370 Infrastrukturanlagen":
        return [
          "371	Anlagen für den Straßenverkehr",
          "372	Anlagen für den Schienenverkehr",
          "373	Anlagen für den Flugverkehr",
          "374	Anlagen des Wasserbaus",
          "375	Anlagen der Abwasserentsorgung",
          "376	Anlagen der Wasserversorgung",
          "377	Anlagen der Energie- und Informationsversorgung",
          "378	Anlagen der Abfallentsorgung",
          "379	Sonstiges zu KG 370"
        ];
      case "380 Baukonstruktive Einbauten":
        return [
          "381	Allgemeine Einbauten",
          "383	Landschaftsgestalterische Einbauten",
          "384	Mechanische Einbauten",
          "385	Einbauten in Konstruktionen des Ingenieurbaus",
          "386	Orientierungs- und Informationssysteme",
          "387	Schutzeinbauten",
          "388	Sonstiges zur KG 380"
        ];
      case "390 Sonstige Maßnahmen für Baukonstruktionen":
        return [
          "391	Baustelleneinrichtung",
          "392	Gerüste",
          "393	Sicherungsmaßnahmen",
          "394	Abbruchmaßnahmen",
          "395	Instandsetzungen",
          "396	Materialentsorgung",
          "397	Zusätzliche Maßnahmen",
          "398	Provisorische Baukonstruktionen",
          "399	Sonstiges zu KG 390"
        ];
      case "400 Bauwerk - Technische Anlagen":
        return [];
      case "410 Abwasser-, Wasser-, Gasanlagen":
        return [
          "411	Abwasseranlagen",
          "412	Wasseranlagen",
          "413	Gasanlagen",
          "419	Sonstiges zu KG 410"
        ];
      case "420 Wärmeversorgungsanlagen":
        return [
          "421	Wärmeerzeugungsanlagen",
          "422	Wärmeverteilnetze",
          "423	Raumheizflächen",
          "424	Verkehrsheizflächen",
          "429	Sonstiges zu KG 420"
        ];
      case "430 Raumlufttechnische Anlagen":
        return [
          "431	Lüftungsanlagen",
          "432	Teilklimaanlagen",
          "433	Klimaanlagen",
          "434	Kälteanlagen",
          "439	Sonstiges zu KG 430"
        ];
      case "440 Elektrische Anlagen":
        return [
          "441	Hoch- und Mittelspannungsanlagen",
          "442	Eigenstromversorgungsanlagen",
          "443	Niederspannungsschaltanlagen",
          "444	Niederspannungsinstallationsanlagen",
          "445	Beleuchtungsanlagen",
          "446	Blitzschutz- und Erdungsanlagen",
          "447	Fahrleitungssysteme",
          "449	Sonstiges zu KG 440"
        ];
      case "450 Kommunikations., sicherheits- und informationstechnische Anlagen":
        return [
          "451	Telekommunikationsanlagen",
          "452	Such- und Signalanlagen",
          "453	Zeitdienstanlagen",
          "454	Elektroakustische Anlagen",
          "455	Fernseh- und Antennenanlagen",
          "456	Gefahrenmelde- und Alarmanlagen",
          "457	Übertragungsnetze",
          "458	Verkehrsbeeinflussungsanlagen",
          "459	Sonstiges zu KG 450"
        ];
      case "460 Förderanlagen":
        return [
          "461	Aufzugsanlagen",
          "462	Fahrtreppen, Fahrsteige",
          "463	Befahranlagen",
          "464	Transportanlagen",
          "465	Krananlagen",
          "467	Hydraulikanlagen",
          "469	Sonstiges zu KG 460"
        ];
      case "470 Nutzungsspezifische Anlagen":
        return [
          "471	Küchentechnische Anlagen",
          "472	Wäscherei-, Reinigungs- und badetechnische Anlagen",
          "473	Medienversorgungsanlagen, Medizin- und labortechnische Anlagen",
          "474	Feuerlöschanlagen",
          "475	Prozesswärme-, kälte- und -luftanlagen",
          "476	Weitere nutzungsspezifische Anlagen",
          "477	Verfahrenstechnische Anlagen, Wasser, Abwasser und Gase",
          "478	Verfahrenstechnische Anlagen, Feststoffe, Wertstoffe und Abfälle",
          "479	Sonstiges zu KG 470"
        ];
      case "480 Gebäudeautomation":
        return [
          "481	Automationseinrichtungen",
          "482	Schaltschränke, Automationsschwerpunkte",
          "483	Automationsmanagement",
          "484	Kabel, Leitungen und Verlegesysteme",
          "485	Datenübertragungsnetze",
          "489	Sonstiges zu KG 480"
        ];
      case "490 Sonstige Maßnahmen für technische Anlagen":
        return [
          "491	Baustelleneinrichtung",
          "492	Gerüste",
          "493	Sicherungsmaßnahmen",
          "494	Abbruchmaßnahmen",
          "495	Instandsetzungen",
          "496	Materialentsorgung",
          "497	Zusätzliche Maßnahmen",
          "498	Provisorische technische Anlagen",
          "499	Sonstiges zu KG 490"
        ];
      case "500 Außenanlagen":
        return [];
      case "510 Erdbau":
        return [
          "511	Herstellung",
          "512	Umschließung",
          "513	Wasserhaltung",
          "514	Vortrieb",
          "519	Sonstiges zu KG 510"
        ];
      case "520 Gründung, Unterbau":
        return [
          "521	Baugrundverbesserung",
          "522	Gründungen und Bodenplatten",
          "523	Gründungsbeläge",
          "524	Abdichtungen und Bekleidungen",
          "525	Dränagen",
          "529	Sonstiges zu KG 520"
        ];
      case "530 Oberbau, Deckschichten":
        return [
          "531	Wege",
          "532	Straßen",
          "533	Plätze, Höfe, Terrassen",
          "534	Stellplätze",
          "535	Sportplatzflächen",
          "536	Spielplatzflächen",
          "537	Gleisanlagen",
          "538	Flugplatzflächen",
          "539	Sonstiges zu KG 530"
        ];
      case "540 Baukonstruktionen":
        return [
          "541	Einfriedungen",
          "542	Schutzkonstruktionen",
          "543	Wandkonstruktionen",
          "544	Rampen, Treppen, Tribünen",
          "545	Überdachungen",
          "546	Stege",
          "547	Kanal- und Schachtkonstruktionen",
          "548	Wasserbecken",
          "549	Sonstiges zu KG 540"
        ];
      case "550 Technische Anlagen":
        return [
          "551	Abwasseranlagen",
          "552	Wasseranlagen",
          "553	Anlagen für Gase und Flüssigkeiten",
          "554	Wärmeversorgungsanlagen",
          "555	Raumlufttechnische Anlagen",
          "556	Elektrische Anlagen",
          "557	Kommunikations-, sicherheits- und informationstechnische Anlagen",
          "558	Nutzungsspezifische Anlagen",
          "559	Sonstiges zu KG 550"
        ];
      case "560 Einbauten in Außenanlagen und Freiflächen":
        return [
          "561	Allgemeine Einbauten",
          "562	Besondere Einbauten",
          "563	Orientierungs- und Informationssysteme",
          "569	Sonstiges zu KG 560"
        ];
      case "570 Vegetationsflächen":
        return [
          "571	Vegetationstechnische Bodenbearbeitung",
          "572	Sicherungsbauweisen",
          "573	Pflanzflächen",
          "574	Rasen- und Saatflächen",
          "579	Sonstiges zu KG 570"
        ];
      case "590 Sonstige Außenanlagen":
        return [
          "591	Baustelleneinrichtung",
          "592	Gerüste",
          "593	Sicherungsmaßnahmen",
          "594	Abbruchmaßnahmen",
          "595	Instandsetzungen",
          "596	Materialentsorgung",
          "597	Zusätzliche Maßnahmen",
          "598	Provisorische Außenanlagen",
          "599	Sonstiges zu KG 590"
        ];
      case "600 Ausstattung und Kunstwerke":
        return [];
      case "610 Allgemeine Ausstattung":
        return [];
      case "620 Besondere Austattung":
        return [];
      case "630 Informationstechnische Ausstattung":
        return [];
      case "640 Kunstobjekte":
        return [
          "641	Kunstobjekte",
          "642	Künstlerische Gestaltung des Bauwerks",
          "643	Künstlerische Gestaltung der Außenanlagen und Freiflächen",
          "629	Sonstiges zu KG 620"
        ];
      case "690 Sonstige Ausstattung":
        return [];
      case "700 Baunebenkosten":
        return [];
      case "710 Bauherrenaufgaben":
        return [
          "711	Projektleitung",
          "712	Bedarfsplanung",
          "713	Projektsteuerung",
          "714	Sicherheits- und Gesundheitsschutzkoordination",
          "715	Vergabeverfahren",
          "719	Sonstiges zu KG 710"
        ];
      case "720 Vorbereitung der Objektplanung":
        return [
          "721	Untersuchungen",
          "722	Wertermittlungen",
          "723	Städtebauliche Leistungen",
          "724	Landschaftsplanerische Leistungen",
          "725	Wettbewerbe",
          "729	Sonstiges zu KG 720"
        ];
      case "730 Objektplanung":
        return [
          "731	Gebäude und Innenräume",
          "732	Freianlagen",
          "733	Ingenieurbauwerke",
          "734	Verkehrsanlagen",
          "735	Tragwerksplanung",
          "736	Technische Ausrüstung",
          "739	Sonstiges zu KG 730"
        ];
      case "740 Fachplanung":
        return [
          "743	Bauphysik",
          "744	Geotechnik",
          "745	Ingenieurvermessungen",
          "746	Lichttechnik, Tageslichttechnik",
          "747	Brandschutz",
          "748	Altlasten, Kampfmittel, kulturhistorische Funde",
          "749	Sonstiges zu KG 740"
        ];
      case "750 Künstlerische Leistungen":
        return ["751	Kunstwettbewerbe", "752	Honorare", "759	Sonstiges zu KG 750"];
      case "760 Allgemeine Baunebenkosten":
        return [
          "761	Gutachten und Beratung",
          "762	Prüfungen, Genehmigungen, Abnahmen",
          "763	Bewirtschaftungskoten",
          "764	Bemusterungskosten",
          "766	Versicherungen",
          "779	Sonstiges zu KG 760"
        ];
      case "790 Sonstige Baunebenkosten":
        return ["791	Bestandsdokumentation", "799	Sonstiges zur KG 790"];
      case "800 Finanzierung":
        return [];
    }
  };

  calcBudgetBrutto(netto, ust) {
    var budgetBrutto = 0;
    var ustValue = ust / 100 + 1;
    budgetBrutto = netto * ustValue;
    return budgetBrutto;
  }

  returnSubkostengruppenObjects = kostengruppe => {
    const budget_netto = 0;
    const ust = 19;
    var subkostengruppen = [];
    this.getSubkostengruppen(kostengruppe).forEach(subkostengruppe => {
      subkostengruppen.push({
        name: subkostengruppe,
        budget_netto: budget_netto,
        budget_brutto: this.calcBudgetBrutto(budget_netto, ust),
        ust: ust
      });
    });
    return subkostengruppen;
  };

  // TODO: Implement value change functionality
  render() {
    const project = this.props.project;
    return (
      <Container className="budget p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Budgetverteilung</b>
                  </h5>
                </Col>
                <Col>
                  {/* <Button className="float-right">Budget anpassen</Button> */}
                  <AddKostengruppeModal />
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <thead>
                    <tr className="greyed-out">
                      <th>Kostengruppe</th>
                      <th>Aktuelles Budget (Netto)</th>
                      <th>USt.</th>
                      <th>Aktuelles Budget (Brutto)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* project?.budget[0]?.budget_netto != null */}
                    {project?.budget?.length ? (
                      project?.budget?.map(kostengruppe => {
                        return this.kostengruppeTableRow(kostengruppe);
                      })
                    ) : (
                      <p className="greyed-out pt-3 px-3">
                        Keine kostengruppen in dieser Baustelle.
                      </p>
                    )}
                    <tr className="table-footer">
                      <td>
                        <b>Gesamtsumme</b>
                      </td>
                      <td>
                        {this.calcKostengruppenBudgetNetto(project.budget)}€
                      </td>
                      <td></td>
                      <td>
                        {this.calcKostengruppenBudgetBrutto(project.budget)}€
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProjectBudgetTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectBudgetTab));
