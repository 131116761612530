import {
    DASHBOARD_EXPENSES_WEEKLY_CALCULATED, DASHBOARD_EXPENSES_WEEKLY
  } from "../actions/types";
  
  const initialState = {
    expenses_weekly: 0,
    income_weekly: 0,
    income_by_date: 0,
    expenses_by_date: 0
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case DASHBOARD_EXPENSES_WEEKLY_CALCULATED:
        return {
          ...state,
          expenses_weekly: action.payload,
        };
      case DASHBOARD_EXPENSES_WEEKLY: 
        return {
            ...state,
            expenses_by_date: action.payload
        }
      default:
        return state;
    }
  }
  