import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

// import { Route, BrowserRouter, Switch } from "react-router-dom";
// import LoginPage from "./components/auth/LoginPage";
// import SettingsPage from "./components/SettingsPage";
// import ProtectedRoute from "./AuthRoute";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
