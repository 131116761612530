import React, { Component } from "react";
import { Container, Badge, Table, Row, Card, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getProject } from "../../../../../../../../actions/projectActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ProjectSingleVergabeeinheitTab extends Component {
  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.props.getProject(projectId);
  }

  getInvoiceIfPaid = invoice => {
    if (invoice.status == 0 || invoice.status == 2) {
      return false;
    } else if (invoice.status == 1) {
      return true;
    }
  };

  // Get the right fields in (backend & frontend)
  vergabeeinheitListItem = vergabeeinheit => {
    return (
      <tr>
        <td>{vergabeeinheit.status}</td>
        <td>{vergabeeinheit.designation}</td>
        <td>{vergabeeinheit.budget_netto}</td>
      </tr>
    );
  };

  render() {
    // const project = this.props.project;
    return (
      <Container className="vergabeeinheit p-0">
        <Row className="mb-2 mt-3">
          <Col>
            <Badge className={"status-col mr-3 float-left"}>Status</Badge>
            <h5>
              <b>VE 5 - Innenausstattung</b>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Überblick</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <tbody>
                    <tr>
                      <td>Immobilie</td>
                      <td>VE 2 - Erdarbeiten</td>
                    </tr>
                    <tr>
                      <td>Projekt</td>
                      <td>Bauunternehmen Mirkwald</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>Stadt Berlin</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>38800</td>
                    </tr>
                    <tr>
                      <td>Freigabeprozess</td>
                      <td>01_190</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Budgetherkunft</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <thead>
                    <tr>
                      <th>Kostengruppe</th>
                      <th>Budget (netto)</th>
                      <th>Verteilung in %</th>
                      <th>Betrag (netto)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>340 Innenwände</td>
                      <td>100.000,00 €</td>
                      <td>10%</td>
                      <td>10.000,00 €</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Finanzübersicht (netto)</b>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Table className="custom-table">
                  <tbody>
                    <tr>
                      <td>Ausgangsbudget</td>
                      <td>53.444,00 €</td>
                    </tr>
                    <tr>
                      <td>Aktuelles Budget</td>
                      <td>53.444,00 €</td>
                    </tr>
                    <tr>
                      <td>Gesamtes Auftragsvolumen</td>
                      <td>53.444,00 €</td>
                    </tr>
                    <tr>
                      <td>Geplante Aufträge</td>
                      <td>2.000,00 €</td>
                    </tr>
                    <tr>
                      <td>Rückstellungen</td>
                      <td>5.600,00€</td>
                    </tr>
                    <tr>
                      <td>Offene Nachträge</td>
                      <td>0,00 €</td>
                    </tr>
                    <tr>
                      <td>Risiken</td>
                      <td>0,00 €</td>
                    </tr>
                    <tr>
                      <td>Realisierte Forderungen</td>
                      <td>0,00 €</td>
                    </tr>
                    <tr>
                      <td>Leistungsstand in €</td>
                      <td>0,00 €</td>
                    </tr>
                    <tr>
                      <td>Leistungsstand in %</td>
                      <td>0,00 €</td>
                    </tr>
                    <tr>
                      <td>Einbehalte</td>
                      <td>1.000,00 €</td>
                    </tr>
                    <tr>
                      <td>Freigegebene Beträge</td>
                      <td>32.100,00 €</td>
                    </tr>
                    <tr>
                      <td>Ausgezahlte Beträge</td>
                      <td>0,00 €</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProjectSingleVergabeeinheitTab.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProject }
)(withRouter(ProjectSingleVergabeeinheitTab));
