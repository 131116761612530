import React, { Component } from "react";
import {
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
// import { withRouter } from "react-router-dom";
// import { getProject } from "../../actions/projectActions";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

class NotificationsTab extends Component {
  // componentDidMount() {
  // 	const projectId = this.props.match.params.id;
  // 	this.props.getProject(projectId);
  // }
  render() {
    // const project = this.props.project;
    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
        <Card>
          <p>
            <strong>Notifications Settings</strong>
          </p>
          <p>*Checkboxes/Toggles for choosing what and where to notify*</p>
        </Card>
        </Col>
        </Row>
        <Row>
          <Col>
        <Card>
          <p>
            <strong>Notification Channels</strong>
          </p>
          <p>*Explanation*</p>
          <Form className="custom-form"
          // onSubmit={this.onSubmit}
          >
            <FormGroup>
              <Label for="email">Mobile Number</Label>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Mobile number"
                className="mb-3"
                onChange={this.onChange}
              />
              <Button className="btn-white">Add mobile number</Button>
              {/* <Link to="/register">Register</Link> */}
            </FormGroup>
          </Form>
          <Button className="custom-btn">Enable desktop notifications</Button>
        </Card>
        </Col>
        </Row>
        {/* <ListGroup>
          
          <ListGroupItem className="justify-content-between mb-2">
            {"Activity"}
          </ListGroupItem>
        </ListGroup> */}
      </Container>
    );
  }
}

// ProjectActivityTab.propTypes = {
// 	getProject: PropTypes.func.isRequired,
// 	project: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
// 	project: state.project
// });

// export default connect(
// 	mapStateToProps,
// 	{ getProject }
// )(withRouter(ProjectActivityTab));

export default NotificationsTab;
