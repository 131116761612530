export const INVOICE_UPLOADING = "INVOICE_UPLOADING";
export const INVOICE_UPLOADED = "INVOICE_UPLOADED";
export const GET_COMPANY_FROM_USER = "GET_COMPANY_FROM_USER";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const TOGGLE_PROJECT_IS_ARCHIVED = "TOGGLE_IS_ARCHIVED";
export const TOGGLE_PROJECT_IS_COMPLETE = "TOGGLE_IS_COMPLETE";
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_CRAFTSMAN = "ADD_CRAFTSMAN";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const DASHBOARD_EXPENSES_WEEKLY = "DASHBOARD_EXPENSES_WEEKLY";
export const DASHBOARD_EXPENSES_WEEKLY_CALCULATED =
  "DASHBOARD_EXPENSES_WEEKLY_CALCULATED";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_KOSTENGRUPPE = "ADD_KOSTENGRUPPE";
export const ADD_HISTORY = "ADD_HISTORY";
export const ADD_COSTGROUP = "ADD_COSTGROUP";
export const ADD_ALLOCATIONUNIT = "ADD_ALLOCATIONUNIT";
export const ADD_ASSIGNMENT = "ADD_ASSIGNMENT";
