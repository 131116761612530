import axios from "axios";
import { DASHBOARD_EXPENSES_WEEKLY, DASHBOARD_EXPENSES_WEEKLY_CALCULATED } from "./types";
import client from '../helper/apolloClient';
import gql from 'graphql-tag';

export const getDashboardExpensesWeekly = (start_date, end_date) => dispatch => {
	client.query({
        variables: {start_date, end_date},
		query: gql`
            query companyInvoicesByDate($start_date: String!, $end_date: String!) {
                companyInvoicesByDate(start_date: $start_date, end_date: $end_date) {
                    price
                    paid_date
                }
            }
		`,
	})
	.then(res => {
        dispatch({type: DASHBOARD_EXPENSES_WEEKLY_CALCULATED, payload: countInvoices(res.data.companyInvoicesByDate) })
        dispatch({type: DASHBOARD_EXPENSES_WEEKLY, payload: res.data.companyInvoicesByDate })
    }).catch(err => {
      console.log(err)
      // dispatch(returnErrors(err.response.data, err.response.status));
    });
};

const countInvoices = (invoices) => {
    let price = 0;
    invoices.map((invoice) => {
        price = price + invoice.price
    } )
    return price;
}
