import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
  NavItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logout from "../../../authentication/Logout";

import Avatar from "@material-ui/core/Avatar";

import { Link } from "react-router-dom";

class NotificationsModal extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  inboxItem = (creater, type) => {
    switch (type) {
      case "NEW_INVOICE":
        return (
          <Button className="project-dashboard-notification">
            {/* TODO: Fix proportions & Add notification type specific icons to each notification item */}
            {/* <FontAwesomeIcon className="mr-3" icon={faBell} />{creater} send you and Invoice */}
            <Row>
              <Col className="center-vertically" sm={2}>
                {/* TODO: Set user's Avatar image if existent */}
                {creater.avatar ? (
                  <Avatar src="../../assets/DefaultProfilePic.svg" />
                ) : (
                  <Avatar src="../../assets/DefaultProfilePic.svg" />
                )}
              </Col>
              <Col sm={7}>
                <Row>
                  <p className="path">
                    Factory Berlin > Corkovic GmbH > Rechnung
                  </p>
                </Row>
                <Row>
                  <p>
                    <b>{creater} hat eine Rechnung hochgeladen</b>
                  </p>
                  <p className="greyed-out">Vor 49 Minuten</p>
                </Row>
              </Col>
              <Col sm={3}>
                <a className="link float-right" href="#">
                  Ansehen
                </a>
              </Col>
            </Row>
          </Button>
        );
      default:
        break;
    }
  };
  render() {
    const { company } = this.props.company;
    return (
      <div>
        <NavLink onClick={this.toggle} href="#">
          <FontAwesomeIcon icon={faBell} />
        </NavLink>

        <Modal
          className="list-modal"
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>Notifications</ModalHeader>
          <ModalBody>
            {company?.inbox.length ? (
              company?.inbox.map(item => {
                return this.inboxItem(item.creater, item.type);
              })
            ) : (
              <p className="greyed-out pt-3 px-3">
                You have no notifications at this time...
              </p>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  company: state.company
});

export default connect(
  mapStateToProps,
  null
)(NotificationsModal);
