import {
  GET_PROJECTS,
  GET_PROJECT,
  ADD_PROJECT,
  TOGGLE_PROJECT_IS_ARCHIVED,
  TOGGLE_PROJECT_IS_COMPLETE,
  DELETE_PROJECT,
  PROJECTS_LOADING,
  ADD_INVOICE,
  ADD_CRAFTSMAN,
  ADD_COSTGROUP,
  ADD_ALLOCATIONUNIT,
  ADD_ASSIGNMENT,
  ADD_HISTORY
} from "../actions/types";

const initialState = {
  projects: [],
  invoices: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      };
    case GET_PROJECT:
      console.log(action)
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(
          project => project._id !== action.payload
        )
      };
    case TOGGLE_PROJECT_IS_ARCHIVED:
      return {
        ...state,
        is_archived: action.is_archived,
        loading: false
      };
    case TOGGLE_PROJECT_IS_COMPLETE:
      return {
        ...state,
        is_complete: action.is_complete,
        loading: false
      };
    case ADD_COSTGROUP:
      return {
        ...state,
        budget: [action.payload, ...state.budget],
        loading: false
      };
    case ADD_INVOICE:
      return {
        ...state,
        invoices: [action.invoice, ...state.invoices],
        loading: false
      };
    case ADD_CRAFTSMAN:
      return {
        ...state,
        craftsmen: [action.craftsmen, ...state.craftsmen],
        loading: false
      };
    case ADD_ALLOCATIONUNIT:
      return {
        ...state,
        allocationunit: [action.payload, ...state.allocationunit],
        loading: false
      };
    case ADD_ASSIGNMENT:
      return {
        ...state,
        assignment: [action.assignment, ...state.assignment],
        loading: false
      };
    case ADD_HISTORY:
      return {
        ...state,
        history: [action.history, ...state.history],
        loading: false
      };
    case ADD_PROJECT:
      return {
        ...state,
        projects: [action.payload, ...state.projects]
      };
    case PROJECTS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
