import axios from "axios";

import { returnErrors } from "./errorActions";

import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "./types";
import client from "../helper/apolloClient";

import { API_LINK } from "../helper/constants";
import { LOGIN } from "../graphql/mutation/auth/login";
import { REGISTER } from "../graphql/mutation/auth/register";
// Check token and load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });

  axios
    .get("/api/auth/user", tokenConfig(getState))
    .then(res =>
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
      });
    });
};

// Register User
export const register = ({ name, email, password }) => dispatch => {
  client
    .mutate({
      variables: { name: name, email: email, password: password },
      mutation: REGISTER
    })
    .then(res => {
      console.log(res);
      console.log(API_LINK);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.login
      });
    })
    .catch(err => {
      console.log(err);
      console.log(API_LINK);
      console.log(process.env);
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({
        type: REGISTER_FAIL
      });
    });
};

export const login = ({ email, password }) => dispatch => {
  client
    .mutate({
      variables: { email: email, password: password },
      mutation: LOGIN
    })
    .then(res => {
      console.log(res);
      console.log(API_LINK);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.login
      });
    })
    .catch(err => {
      console.log(err);
      console.log(API_LINK);
      console.log(process.env);
      dispatch(
        returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
      );
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// Logout user
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

// Set up config/headers and token
export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
};
