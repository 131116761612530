import axios from "axios";
import { GET_COMPANY_FROM_USER } from "./types";
import client from '../helper/apolloClient';
import gql from 'graphql-tag';

export const getCompanyFromUser = () => dispatch => {
	client.query({
		query: gql`
			query company {
				companyFromUser {
					name
					email
					address {
            			street_address
            			zip_code
            			city
					}
					telephone
					members {
						id
						name
						email
						role
					}
					bank_details {
						bic
						iban
					}
					tax_id
					commercial_register {
						inst_location
						registry_num
					}
					inbox {
						id
						creater
						type
						sourceId
					}
				}
			}
		`,
	})
	.then(res => dispatch({type: GET_COMPANY_FROM_USER, payload: res.data.companyFromUser })).catch(err => {
      console.log(err)
      // dispatch(returnErrors(err.response.data, err.response.status));
    });
};
