import React, { Component } from "react";
import {
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Table,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import AddCompanyMemberModal from "./AddCompanyMemberModal";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
// import { withRouter } from "react-router-dom";
// import { getProject } from "../../actions/projectActions";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

class CompanyTab extends Component {
  // componentDidMount() {
  // 	const projectId = this.props.match.params.id;
  // 	this.props.getProject(projectId);
  // }
  membersModuleItem = member => {
    return <div>{/* Company member item */}</div>;
  };

  render() {
    // const project = this.props.project;
    const members = [];

    return (
      <Container className="p-0">
        <Row className="mt-3">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>
                    <b>Members</b>
                  </h5>
                </Col>
                <Col>
                  <AddCompanyMemberModal />
                </Col>
              </Row>
              <Row>
                <Col>
                  {members?.length ? (
                    <Table>
                      <thead>
                        <tr className="greyed-out">
                          <th>Status</th>
                          <th>Name</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {members?.map(member => {
                          return this.membersModuleItem(member);
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <ListGroup className="pt-2">
                      <ListGroupItem className="center greyed-out">
                        There are no members yet...
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Col>
                {/* TODO: Test with proper User objects in 'Members' Object */}
                {/* TODO: Add Status to Members/ Users */}
                {/* TODO: Make '...' button for Company Member list item */}

                {/* <ListGroup className="mt-3">
                    {company.members.map(member => {
                      return (
                        <ListGroupItem className="justify-content-between invoice-list mb-2">
                        {member.name}
                        <span className="projectlist-float-right">
                          <strong className="mr-3">{member.role}</strong>
                          <div className="more-info-button">
                            <ProjectCraftsmanModal
                              project={project}
                              craftsman={craftsman}
                            />
                          </div>
                        </span>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup> */}
              </Row>
            </Card>
            <Card>
              <p>
                <strong>Unternehmensinformation</strong>
              </p>
              <p>*Explanation*</p>
              <Form
                className="custom-form"
                // onSubmit={this.onSubmit}
              >
                <FormGroup>
                  <Label for="email">Name</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Name"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Label for="email">Anschrift</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Anschrift"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Label for="email">Telefon</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Telefon"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Button
                    className="custom-btn"
                    color="dark"
                    style={{ marginTop: "2rem" }}
                    block
                  >
                    Informationen aktualisieren
                  </Button>
                  {/* <Link to="/register">Register</Link> */}
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <p>
                <strong>Rechnungsinformation</strong>
              </p>
              <p>*Explanation*</p>
              <Form
                className="custom-form"
                // onSubmit={this.onSubmit}
              >
                <FormGroup>
                  <Label for="email">Bankverbindung</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Bankverbindung"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Label for="email">Steuernummer</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Steuernummer"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Label for="email">Handelsregisternummer</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Handelsregisternummer"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Button
                    className="custom-btn"
                    color="dark"
                    style={{ marginTop: "2rem" }}
                    block
                  >
                    Rechnungsinformation aktualisieren
                  </Button>
                  {/* <Link to="/register">Register</Link> */}
                </FormGroup>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* <ListGroup>
          
          <ListGroupItem className="justify-content-between mb-2">
            {"Activity"}
          </ListGroupItem>
        </ListGroup> */}
      </Container>
    );
  }
}

// ProjectActivityTab.propTypes = {
// 	getProject: PropTypes.func.isRequired,
// 	project: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
// 	project: state.project
// });

// export default connect(
// 	mapStateToProps,
// 	{ getProject }
// )(withRouter(ProjectActivityTab));

export default CompanyTab;
