import React, { Component } from "react";
import { Container, ListGroup, ListGroupItem, Badge, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import {
  getProjects,
  deleteProject
} from "../../../../../actions/projectActions";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../../../../helper/history";

class ProjectsList extends Component {
  componentDidMount() {
    this.props.getProjects();
  }

  onDeleteClick = id => {
    this.props.deleteProject(id);
  };

  // handleProjectClick = id => {
  //   history.push('/user')
  // }

  render() {
    const projects = this.props.project.projects;
    const isArchived = this.props.isArchived;
    const page = this.props.page;

    // TODO: Clear "uploadInvoicePage" components
    return (
      <div className="project-list">
        <Table>
          <thead>
            <tr className="greyed-out">
              <th>Status</th>
              <th>Auftraggeber</th>
              <th>Budget</th>
              <th>Anzahl der Aufträge</th>
              <th>Hinzugefügt am</th>
            </tr>
          </thead>
          <tbody>
            {/* <TransitionGroup className="projects-list"> */}
            {projects.map(
              ({
                id,
                address,
                name,
                client,
                start_date,
                end_date,
                budget,
                is_complete,
                is_archived
              }) => {
                if (isArchived == is_archived) {
                  return (
                    <CSSTransition key={id} timeout={500} classNames="fade">
                      <tr
                        className="project-item"
                        onClick={() => history.push(`/myProject/${id}`)}
                      >
                        {/* <Link exact to={`/myProject/${id}`}> */}
                        <td>
                          <Badge
                            className={
                              "status-col " +
                              (is_complete ? "complete" : "incomplete")
                            }
                          >
                            {is_complete ? "Fertig" : "Aktiv"}
                          </Badge>
                        </td>
                        <td>{client}</td>
                        <td>
                          {budget.budget_netto}
                          125.000€
                        </td>
                        <td>0 Aufträge</td>
                        <td>{start_date.split("T")[0]}</td>
                        {/* </Link> */}
                      </tr>

                      {/* <ListGroupItem className="justify-content-between">
                            <Badge className={
                                "status-col " +
                                (is_complete ? "complete" : "incomplete")
                              }>{is_complete ? "Fertig" : "Aktiv"}</Badge> */}
                      {/* <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                "mr-2 " +
                                (is_complete ? "complete" : "incomplete")
                              }
                            /> */}
                      {/* <span>Factory Berlin</span>
                            <span>{budget} €</span>
                            <span>0 Aufträge</span>
                            <span className="projectlist-float-right">
                              <span className="projectlist-date">
                                {start_date.split("T")[0]}
                              </span>
                            </span>
                          </ListGroupItem> */}
                    </CSSTransition>
                  );
                }
              }
            )}
            {/* </TransitionGroup>   */}
          </tbody>
        </Table>
        {/* <ListGroup> */}
        {/* TODO: Add CSS & fill with labels */}
        {/* <ListGroupItem className="project-list-labels justify-content-between" disabled tag="a" href="#"><span className="status-col">Status</span><span className="client-col">Auftraggeber</span><span className="client-col">Budget</span><span className="client-col">Anzahl der Aufträge</span><span>Hinzugefügt am</span></ListGroupItem>
            <TransitionGroup className="projects-list">
              {projects.map(
                ({
                  id,
                  address,
                  name,
                  start_date,
                  end_date,
                  budget,
                  is_complete,
                  is_archived
                }) => {
                  if (isArchived == is_archived) {
                    return (
                      <CSSTransition key={id} timeout={500} classNames="fade">
                        <Link exact to={`/myProject/${id}`}>
                          <ListGroupItem className="justify-content-between">
                            <Badge className={
                                "status-col " +
                                (is_complete ? "complete" : "incomplete")
                              }>{is_complete ? "Fertig" : "Aktiv"}</Badge>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={
                                "mr-2 " +
                                (is_complete ? "complete" : "incomplete")
                              }
                            />
                            <span>Factory Berlin</span>
                            <span>{budget} €</span>
                            <span>0 Aufträge</span>
                            <span className="projectlist-float-right">
                              <span className="projectlist-date">
                                {start_date.split("T")[0]}
                              </span>
                            </span>
                          </ListGroupItem>
                        </Link>
                      </CSSTransition>
                    );
                  }
                }
              )}
            </TransitionGroup>
          </ListGroup> */}
      </div>
    );
  }
}

{
  /* <Button
                      className="remove-btn"
                      color="danger"
                      size="sm"
                      onClick={this.onDeleteClick.bind(this, _id)}
                    >
                      &times;
                    </Button> */
}

ProjectsList.propTypes = {
  getProjects: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  isArchived: PropTypes.bool
};

const mapStateToProps = state => ({
  project: state.project
});

export default connect(
  mapStateToProps,
  { getProjects, deleteProject }
)(ProjectsList);
