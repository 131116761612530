import gql from 'graphql-tag';
 // Login user
export const CREATE_COSTGROUP_MUTATION = gql`
mutation createCostGroup ($projectId: String!, $costgroup: CostgroupInput!) {
	createCostGroup(projectId: $projectId, costgroup: $costgroup) {
		id
		id_number
		name
		budget_netto
		budget_brutto
		ust
		subcostgroup {
		  id
		  name
		  budget_netto
		  budget_brutto
		}
	}
  }
`;